import React, {useCallback, useEffect, useState} from "react";
import {isEmpty} from "lodash";
import {useLocation} from "react-router-dom";

import {ItemInformation} from "./ItemInformation/ItemInformation";
import {AdvancedSearch} from "../../components/AdvancedSearch/AdvancedSearch";

export const AdminDashboard = () => {
    const location = useLocation();
    const body = document.body;


    const [locationData, setLocationData] = useState({});
    const [venuesSearchResult, setVenuesSearchResult] = useState([]);
    const [userData, setUserData] = useState({});

    const [clearForm, setClearForm] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const clearHandle = useCallback((event) => {
        const {value} = event.target;
        setClearForm(!value)
    }, []);

    useEffect(() => {
        if (clearForm === true) {
            setLocationData({});
            setUserData({});
            setVenuesSearchResult([]);
            setClearForm(false);
        }
    }, [clearForm])


    const onChangeTab = useCallback((tabIndex) => {
        setActiveTab(tabIndex);
    }, [setActiveTab]);

    useEffect(() => {
        setLocationData({});
        setVenuesSearchResult([]);
        setUserData({});
    }, [activeTab]);

    useEffect(() => {
        if (location.pathname === '/admin') {
            body.setAttribute('class', 'admin');
        }
    }, [body, location]);

    const onSubmitVenues = useCallback(async (place) => {
        setVenuesSearchResult(place);
    }, []);

    const onSubmitLocation = useCallback((value) => {
        setLocationData(location => ({
            ...location,
            ...value
        }));
    }, [setLocationData]);

    const onChangePhone = useCallback((phone) => {
        setUserData(userData => ({
            ...userData,
            phone,
        }));
    }, [setUserData]);

    const onChangeUserCode = useCallback((qrCode) => {
        setUserData(qrCode);
    }, [setUserData]);

    const renderItemInformation = useCallback(() => {
        if (activeTab !== 0 && isEmpty(userData) && isEmpty(venuesSearchResult)) {
            return null;
        }
        return (
            <ItemInformation
                activeTab={activeTab}
                location={locationData}
                venue={venuesSearchResult}
                user={userData}
                clearForm={clearForm}
            />
        )
    }, [clearForm, activeTab, locationData, userData, venuesSearchResult]);

    return (
        <>
            <AdvancedSearch
                activeTab={activeTab}
                locationData={locationData}
                clearHandle={clearHandle}
                clearForm={clearForm}
                onChangeTab={onChangeTab}
                onSelectPhone={onChangePhone}
                onSubmitVenues={onSubmitVenues}
                onSubmitUser={onChangeUserCode}
                onSubmitLocation={onSubmitLocation}
            />
            {renderItemInformation()}

        </>
    )
};