import React, {useCallback, useState} from "react";
import './SubscribeForm.scss'
import {Checkbox, FormControlLabel, TextField, Button, useMediaQuery} from '@material-ui/core';
import SubscribeService from "../../../services/SubscribeService";

export const SubscribeForm = ({label}) => {
    const width575 = useMediaQuery('(max-width:575px)');

    const [email, setEmail] = useState('');
    const [sendInvite, setCheckedInvite] = useState(true);
    const [sendNews, setCheckedNews] = useState(true);
    const [contactMe, setCheckedContact] = useState(true);
    const [page] = useState(label);

    const [sentSuccessfully, setSentSuccessfully] = useState(false);

    const submitHandler = useCallback(async () => {
        try {
            const response = await SubscribeService.create({
                email,
                sendInvite,
                sendNews,
                contactMe,
                page
            });
            setSentSuccessfully(response.success);
        } catch (e) {
            console.log(e.message)
        }
    }, [contactMe, email, page, sendInvite, sendNews]);


    const handleChangeEmail = useCallback((event) => {
        const {value} = event.target;
        setEmail(value)
    }, []);

    const handleChangeInvite = useCallback(() => {
        setCheckedInvite(!sendInvite)
    }, [sendInvite]);

    const handleChangeNews = useCallback(() => {
        setCheckedNews(!sendNews)
    }, [sendNews]);

    const handleChangeContact = useCallback(() => {
        setCheckedContact(!contactMe)
    }, [contactMe]);

    const renderSubmitButton = useCallback(() => {
        return (
            <Button className='subscribe-form__button' variant="contained"
                    color="primary" disabled={!email} onClick={submitHandler}>Subscribe!</Button>
        )
    }, [email, submitHandler]);

    const renderSuccessMessage = useCallback(() => {
        return (
            <div className='subscribe-success'>
                <p className='subscribe-success__title'>Successfully!</p>
                <p className='subscribe-success__text'>Thanks! You have successfully subscribed to our updates.</p>
            </div>
        )
    }, []);

    return (
        <div className='subscribe'>
            <div className='subscribe-cover'>
                <h1 className='subscribe-title'>Leave Your Email Address to Signup for Updates!</h1>
                {sentSuccessfully ? renderSuccessMessage() : <div className='subscribe-form'>
                    <TextField
                        className='subscribe-form__input'
                        onChange={handleChangeEmail}
                        type='email'
                        variant='outlined'
                        name='email'
                        required
                        value={email}
                        placeholder="Email address"
                        InputProps={{
                            endAdornment: <>{width575 ? null : renderSubmitButton()}</>,
                        }}
                    />
                    {width575 ? renderSubmitButton() : null}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sendInvite}
                                onChange={handleChangeInvite}
                                name="checkedB"
                                color="primary"
                                size={"small"}
                            />
                        }
                        label="Send me an invite when the business app is released"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sendNews}
                                onChange={handleChangeNews}
                                name="checkedB"
                                color="primary"
                                size={"small"}
                            />
                        }
                        label="Send me news and updates about TruLink"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={contactMe}
                                onChange={handleChangeContact}
                                name="checkedB"
                                color="primary"
                                size={"small"}
                            />
                        }
                        label="You may contact me to learn more about my application"
                    />
                </div>}
            </div>
        </div>
    )

}