import React, {useCallback, useEffect, useRef, useState} from "react";
import '../../pages/Search/SearchVenues.scss'
import {PDFTemplate} from "../PDFTemplate/PDFTemplate";
import {useReactToPrint} from "react-to-print";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {Statistics} from "../Statistics/Statistic";
import ReCAPTCHA from "react-google-recaptcha";
import {isEmpty} from "lodash";

export const SearchResult = ({venue}) => {
    const captchaSiteKey = '6LfxMrcZAAAAAGgh1h8oWFREzDN5lXmej0OvsxMP';

    const [dialogOpen, setDialogOpen] = useState(false);
    const [qrURL, setQrURL] = useState('');


    const dialogWindowHandler = useCallback(() => {
        setDialogOpen(prevState => !prevState);
    }, []);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${venue.name.replace(/ /g, '_')}.pdf`,
    });

    useEffect(() => {
        if (!dialogOpen) {
            setQrURL('');
        }
        if (!isEmpty(qrURL) && dialogOpen) {
           return  handlePrint();
        }
    }, [handlePrint, qrURL, dialogOpen]);

    const onChange = useCallback((value) => {
        if (!value) {
            return null;
        }

        handlePrint();
        dialogWindowHandler();
        setDialogOpen(!dialogOpen);
    }, [dialogOpen, dialogWindowHandler, handlePrint])

    return (
        <div className='searchResult' key={venue.id}>
            <h3 className='searchResult-VenueName'>{venue.name}</h3>
            <h5 className='searchResult-VenueLocation'>{venue.location.country && venue.location.country}{venue.location.city && `, ${venue.location.city}`}{venue.location.address && `, ${venue.location.address}`}</h5>
            <Statistics id={venue.id}/>
            <button className='button-blue' onClick={dialogWindowHandler}><span>Generate QR-code</span></button>
            <span className='searchResult-qrPdf'>* we will generate QR-code in PDF format</span>
            <div className='searchResult-learnMore'>
                <a href="/for-merchants">Learn more about QR-codes for business</a>
            </div>
                {/*<ModalQR open={dialogOpen} venue={venue} onClose={dialogWindowHandler} onChangeQr={setQrURL}/>*/}
                <Dialog onClose={dialogWindowHandler} aria-labelledby="customized-dialog-title"
                        className='searchResult-window' open={dialogOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={dialogWindowHandler}>
                    <span
                        className='searchResult-windowTitle'>Please go through verification to generate your QR-code!</span>
                        {dialogOpen ? (
                            <IconButton aria-label="close" className='searchResult-windowTitle__close'
                                        onClick={dialogWindowHandler}>
                                <CloseIcon/>
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent>
                        <div className='searchResult-captcha'>
                            <ReCAPTCHA
                                sitekey={captchaSiteKey}
                                onChange={onChange}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            <div style={{display: 'none'}}><PDFTemplate pdfRef={componentRef} qrURL={qrURL} venue={venue}/></div>
        </div>
    )
}
