import React, {useCallback, useRef, useState} from "react";
import './SearchForm.scss';
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import PlacesAutocomplete from "../PlacesAutocomplete";
import VenueAutocomplete from "../VenueAutocomplete";
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center',
        maxWidth: '700px'
    },
    autoSuggestInput: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
}))

export const SearchForm = ({
                               onSubmit,
                               onCountrySelect,
                               selectedCountry,
                               countryObject,
                               clearForm
                           }) => {
    const [near, setNear] = useState('');
    const width768 = useMediaQuery('(max-width:768px)');
    const classes = useStyles();
    const selectRef = useRef();
    const placeRef = useRef();
    const venueRef = useRef();

    const renderCountries = useCallback(() => {
        if (countryObject) {
            return countryObject.map((country) => {
                return (
                    <option key={country.value} value={country.value}>{country.label}</option>
                )
            })
        }

        return null
    }, [countryObject])


    const scroll = useCallback(() => {
        if (width768) {
            if (selectRef.current !== null) {
                window.scrollTo(0, (selectRef.current.offsetTop - 10));
            }
        }
        return null;
    }, [width768]);

    const scrollPlace = useCallback(() => {
        if (width768) {
            if (placeRef.current !== null) {
                window.scrollTo(0, (placeRef.current.offsetTop - 10));
            }
        }
    }, [width768]);

    const scrollVenue = useCallback(() => {
        if (width768) {
            if (venueRef.current !== null) {
                window.scrollTo(0, (venueRef.current.offsetTop - 10));
            }
        }
    }, [width768])

    return (
        <Grid container className={classes.root}>
            <Grid container item xs={12} sm={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} className={classes.autoSuggestInput} ref={selectRef}>
                    <FormControl fullWidth variant='outlined' onClick={scroll}>
                        <InputLabel id='labelCountry'>Country</InputLabel>
                        <Select
                            labelId='labelCountry'
                            label='Country'
                            native
                            onChange={onCountrySelect}
                            defaultValue={selectedCountry}>
                            {renderCountries()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.autoSuggestInput} ref={placeRef}>
                    <FormControl onClick={scrollPlace} fullWidth>
                        <PlacesAutocomplete
                            countrySelection={selectedCountry.toLowerCase()}
                            onChange={setNear}
                            clearForm={clearForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.autoSuggestInput} ref={venueRef}>
                    <FormControl onClick={scrollVenue} fullWidth>
                        <VenueAutocomplete
                            countrySelection={selectedCountry}
                            near={near}
                            onVenueSelect={onSubmit}
                            clearForm={clearForm}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    )
}
