import React from "react";
import './Jumbotron.scss';
import AppStore from "../../../static/img/AppStore.svg";
import GooglePlay from "../../../static/img/GooglePlay.svg";
import Phones from '../../../static/img/_imagePhones.svg';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Jumbotron = () => {
    return (
        <div className='jumbotron'>
            <div className='jumbotron-action'>
                <div className='jumbotron-text'>
                    <h1 className='jumbotron-text__head'>Link with the people and places you love!</h1>
                    <p className='jumbotron-text__paragraph'>A contactless, community-driven approach to connections made throughout your neighborhood, and
                        beyond! The freedom to create unimaginable professional to personal experiences is in the palm of your
                        hand.</p>
                    <div className='jumbotron-download'>
                        <a href='/#' className='jumbotron-download__img'>
                            <img className='jumbotron-download__svg' src={AppStore} alt="AppStore"/>
                        </a>
                        <a href='/#' className='jumbotron-download__img'>
                            <img className='jumbotron-download__svg' src={GooglePlay} alt="GooglePlay"/>
                        </a>
                    </div>
                </div>

            </div>
            <div className='jumbotron-phones'>
                <img src={Phones} className='jumbotron-phones__img' alt='Phones' />
            </div>
            <div className='jumbotron-action__phones'>
                <LazyLoadImage
                    effect="blur"
                    src={Phones}
                    alt={'phones'}
                    className='jumbotron-action__phones-img'
                />
            </div>
        </div>
    )
}
