import React from "react";
import './../GeographicalArea.scss';

export const GeographicalItem = ({item}) => {

    return (
        <div className='geographical-container__block' key={item.index}>
            <div className='geographical-container__item'>
                <span className='geographical-container__item-title'>{item.country}</span>
            </div>
            <div className='geographical-container__item'>
                <span className='geographical-container__item-count'>{item.checkIns}</span>
            </div>
            <div className='geographical-container__item'>
                <span className='geographical-container__item-count'>{item.scans}</span>
            </div>
        </div>
    )
}