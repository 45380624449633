import React, {useCallback, useEffect, useState} from "react";
import './NavBar.scss';
import {ReactComponent as Logo} from "../../../static/img/logoWhite.svg";
import {Collapse} from 'react-burgers'
import Backdrop from "./SideDrawer/Backdrop";
import SideDrawer from "./SideDrawer/SideDriver";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const NavBar = () => {
    const width1000 = useMediaQuery('(min-width:1000px)');
    const body = document.body;
    const [active, setActive] = useState(false);
    const isActive = (e) => window.location.pathname === e ? 'selected' : null;
    let backdrop;

    useEffect( () => {
        if (width1000) {
            setActive(false);
        }

        return undefined;
    }, [width1000]);

    const menuHandler = useCallback(() => {
        setActive(!active);
        body.classList.toggle('no-scrollable');
    }, [active, body]);

    const backdropClickHandler = useCallback(() => {
        setActive(false);
    }, []);

    if (active) {
        backdrop = <Backdrop click={backdropClickHandler}/>;
    }

    return (
        <div className='header'>
            <div className='header-container'>
                <div className='header-logo'>
                    <a href='/'>
                        <Logo className={active ? 'header-logo_svg-menu' :'header-logo_svg'}/>
                    </a>
                </div>
                <div className="drawer">
                    <Collapse
                        width={22}
                        lineHeight={2}
                        lineSpacing={4}
                        color='#ffffff'
                        active={active}
                        onClick={menuHandler}
                    />
                </div>
                <SideDrawer
                    show={active}
                    menu='side-drawer'
                />
                {backdrop}
                <nav className='header-nav'>
                    <a className={`header-nav-item ${isActive('/for-merchants')}`} href='/for-merchants'>
                            For business
                    </a>
                    <a className={`header-nav-item ${isActive('/search')}`} href='/search'>
                            Get QR-code for a Venue
                    </a>
                </nav>
            </div>
        </div>
    )
}