import React, {useCallback, useState} from "react";
import {SearchForm} from "../../components/SearchForm/SearchForm";
import './SearchVenues.scss';
import {SearchResult} from "../../components/SearchResult/SearchResult";
import {isEmpty} from "lodash";
import countryList from 'react-select-country-list';
import {SubscribeForm} from "../../components/SubscribeForm/SubscribeForm";

export const SearchVenues = () => {
    const countryObject = countryList().getData();
    const [venuesSearchResult, setVenuesSearchResult] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('US');
    const onChangeCountryHandler = useCallback((e) => {
        const {value} = e.target;
        setSelectedCountry(value);
    }, []);

    const onSubmit = useCallback(async (place) => {
        setVenuesSearchResult(place)
    }, []);

    const renderSearch = useCallback(() => {
        if (isEmpty(venuesSearchResult)) {
            return null
        }

        return (
            <SearchResult
                className='searchResult'
                venue={venuesSearchResult}
            />
        )

    }, [venuesSearchResult]);

    return (
        <>
        <div className='searchVenues'>
            <h1 className='searchVenues-title'>We help you create a QR-code for your Venue!</h1>
            <div className='searchVenues-textBox'>
                <p className='searchVenues-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Ullamcorper nunc orci consectetur eget egestas nam aenean elit mauris. Proin convallis augue
                    vel, fringilla consectetur quam rhoncus erat amet.</p>
            </div>
            <h2 className='searchVenues-formTitle'>Search a Venue</h2>
            <SearchForm
                className='searchVenues-form'
                selectedCountry={selectedCountry}
                countryObject={countryObject}
                onCountrySelect={onChangeCountryHandler}
                onSubmit={onSubmit}
            />
            {renderSearch()}
        </div>
            {isEmpty(venuesSearchResult) ? null : <SubscribeForm label='Search a Venue'/>}
        </>
    )
}
