import React, {useCallback} from "react";
import {StatisticPanel} from "./StatisticPanel";

export const StatisticBox = ({statisticSkeleton, statistic, activeTab, tab, notFound}) => {

    const getStatisticLabel = useCallback((key) => {
        switch (key) {
            case "scans":
                return "Scans";
            case "users":
                return "Users";
            case "places":
                return "Places";
            case "contacts":
                return "Contacts";
            case "checkIns":
                return "Check-ins";
            default:
                return "";
        }
    }, []);

    const renderStatisticItem = useCallback(() => {
        const {venue, location, user} = statistic;

        if (activeTab === 0 && tab === 'All time') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Scans'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Users'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Places'}
                        />
                    </>
                )
            }
            return Object.keys(location.allTime).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={location.allTime[statisticKey] ? location.allTime[statisticKey] : 0}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 0 && tab === 'Last Week') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Scans'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Users'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Places'}
                        />
                    </>
                )
            }
            return Object.keys(location.lastWeek).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={location.lastWeek[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 0 && tab === 'Last Month') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Scans'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Users'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Places'}
                        />
                    </>
                )
            }
            return Object.keys(location.lastMonth).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={location.lastMonth[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 0 && tab === 'Custom') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Scans'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Users'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Places'}
                        />
                    </>
                )
            }
            return Object.keys(location.custom).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={location.custom[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 1 && tab === 'All time') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Scans'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Users'}
                        />
                    </>
                )
            }
            return Object.keys(venue.allTime).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={venue.allTime[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 1 && tab === 'Last Week') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Scans'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Users'}
                        />
                    </>
                )
            }
            return Object.keys(venue.lastWeek).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={venue.lastWeek[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 1 && tab === 'Last Month') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Scans'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Users'}
                        />
                    </>
                )
            }
            return Object.keys(venue.lastMonth).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={venue.lastMonth[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 1 && tab === 'Custom') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Scans'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Users'}
                        />
                    </>
                )
            }
            return Object.keys(venue.custom).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={venue.custom[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 2 && tab === 'All time') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Сontacts'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Сheck-ins'}
                        />
                    </>
                )
            }
            return Object.keys(user.allTime).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={user.allTime[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 2 && tab === 'Last Week') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Сontacts'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Сheck-ins'}
                        />
                    </>
                )
            }
            return Object.keys(user.lastWeek).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={user.lastWeek[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 2 && tab === 'Last Month') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Сontacts'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Сheck-ins'}
                        />
                    </>
                )
            }
            return Object.keys(user.lastMonth).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={user.lastMonth[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }
        if (activeTab === 2 && tab === 'Custom') {
            if (notFound === true) {
                return (
                    <>
                        <StatisticPanel
                            data={0}
                            label={'Сontacts'}
                        />
                        <StatisticPanel
                            data={0}
                            label={'Сheck-ins'}
                        />
                    </>
                )
            }
            return Object.keys(user.custom).map((statisticKey, index) => {
                return (
                    <StatisticPanel
                        key={index}
                        data={user.custom[statisticKey]}
                        label={getStatisticLabel(statisticKey)}
                    />
                );
            })
        }

        return null;
    }, [notFound, statistic, tab, activeTab, getStatisticLabel]);

    const renderLoaderStatisticItem = useCallback(() => {
        if (activeTab === 0) {
            return (
                <>
                    <StatisticPanel
                        data='Loading...'
                        label='Scans'
                        loading={true}
                    />
                    <StatisticPanel
                        data='Loading...'
                        label='Users'
                        loading={true}
                    />
                    <StatisticPanel
                        data='Loading...'
                        label='Places'
                        loading={true}
                    />
                </>
            )
        }
        if (activeTab === 1) {
            return (
                <>
                    <StatisticPanel
                        data='Loading...'
                        label='Scans'
                        loading={true}
                    />
                    <StatisticPanel
                        data='Loading...'
                        label='Users'
                        loading={true}
                    />
                </>
            )
        }
        if (activeTab === 2) {
            return (
                <>
                    <StatisticPanel
                        data='Loading...'
                        label='Contacts'
                        loading={true}
                    />
                    <StatisticPanel
                        data='Loading...'
                        label='Check-ins'
                        loading={true}
                    />
                </>
            )
        }
    }, [activeTab]);

    return (
        <div className='info__statistics-data-box'>
            {statisticSkeleton ? renderLoaderStatisticItem() : renderStatisticItem()}
        </div>
    )
}