export const BASE_URL = `https://us-central1-trulink-dev.cloudfunctions.net/api`;
export const USER_URLS = {
    CONTACT_US: `/venues/owner-request`,
    SEARCH: `/venues/search`,
    VENUES_AUTOCOMPLETE: '/venues-autocomplete',
    EMAIL_SUBSCRIBE: '/email-subscription',
    STATISTICS: `/venues/statistics`,
    GENERATE_QR: '/venue/generate-qrcode-url',
    USER_STATISTICS: '/user/admin-statistics',
    VENUE_STATISTICS: '/venue/admin-statistics',
    LOCATION_STATISTICS: '/location-admin-statistics',
    USERS_AUTOCOMPLETE: '/users-autocomplete',
    GEOGRAPHICAL_AREA: '/countries-admin-statistics',
    USER_DIRECT_MESSAGE: '/send-user-message',
    USERS_CONNECTION_MESSAGE: '/send-user-contacts-message',
    VENUE_CHECKINS_MESSAGE: '/send-venue-check-ins-message',
    LOCATION_CHECKINS_MESSAGE: '/send-location-user-message',
    NOTIFICATION_STATISTICS: '/notification-statistics',
};
