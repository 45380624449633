import React from 'react';
import {Router, Route, Switch, Redirect} from "react-router-dom";
import {Home} from "../../pages/Home/Home";
import {history} from "../../../utils/history";
import {Footer} from "../Footer/Footer";
import {NavBar} from "../NavBar/NavBar";
import {Terms} from "../../pages/Terms/Terms";
import {Privacy} from "../../pages/Privacy/Privacy";
import {ContactUs} from "../../pages/ContactUs/ContactUs";
import {SearchVenues} from "../../pages/Search/SearchVenues";
import {ForMerchants} from "../../pages/ForMerchants/ForMerchants";
import {AdminDashboard} from "../../pages/AdminDashboard/AdminDashboard";
import {Messages} from "../../pages/Messages/Messages";

function Routing() {


    return (
        <Router history={history} basename={process.env.PUBLIC_URL}>
            <NavBar/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/terms" component={Terms}/>
                <Route exact path="/privacy" component={Privacy}/>
                <Route exact path="/contact-us" component={ContactUs}/>
                <Route exact path="/search" component={SearchVenues} />
                <Route exact path="/for-merchants" component={ForMerchants}/>
                <Route exact path="/admin" component={AdminDashboard} />
                <Route exact path="/messages" component={Messages} />
                <Redirect from="*" to="/"/>
            </Switch>
            <Footer/>
        </Router>
    )
}

export default Routing;
