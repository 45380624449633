import React, {useCallback, useEffect, useState} from "react";
import {isEmpty} from "lodash";
import {FormControl, InputLabel, DialogTitle, IconButton, Select, TextField, DialogContent, Dialog} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import UserDirectService from "../../../services/UserDirectService";
import VenuesCheckinsMessageService from "../../../services/VenuesCheckinsMessageService";
import UsersConnectionMessageService from "../../../services/UsersConnectionMessageService";
import LocationCheckinsMessageService from "../../../services/LocationCheckinsMessageService";
import moment from "moment";
import Mail from "../../../static/img/mail.png";
import User from '../../../static/img/user.png';
import Time from '../../../static/img/time.png';
import './SendMessage.scss'
import MenuItem from "@material-ui/core/MenuItem";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const SendMessage = ({tab, user, open, venue, nowDate, lastWeek, location, activeTab, statistic, lastMonth, customDate, flagDirect, handleClickOpen}) => {
    const [subject, setSubject] = useState('')
    const [formTitle, setFormTitle] = useState('');
    const [formMessage, setFormMessage] = useState('');
    const [dateRange, setDateRange] = useState({
        fromDate: '',
        toDate: ''
    });

    useEffect(() => {
        setSubject('')
        setFormTitle('');
        setFormMessage('');
    }, [open]);

    useEffect(() => {
        if (tab === 'All Time') {
            setDateRange({});
        }
        if (tab === 'Last Week') {
            setDateRange(prevState => {
                return {
                    ...prevState,
                    fromDate: lastWeek,
                    toDate: nowDate,
                }
            })
        }
        if (tab === 'Last Month') {
            setDateRange(prevState => {
                return {
                    ...prevState,
                    fromDate: lastMonth,
                    toDate: nowDate,
                }
            })
        }
        if (tab === 'Custom') {
            setDateRange(prevState => {
                return {
                    ...prevState,
                    fromDate: moment(customDate.startDate).format('MM-DD-YYYY'),
                    toDate: moment(customDate.endDate).format('MM-DD-YYYY')
                }
            })
        }
    }, [customDate, lastMonth, lastWeek, nowDate, tab]);

    const fetchLocationCheckinsMessage = useCallback(async (params) => {
        try {
            return await LocationCheckinsMessageService.postRequest(params);
        } catch (e) {
            console.log('fetchLocationCheckinsMessage', e.success)
        }
    }, []);

    const fetchVenueCheckinsMessage = useCallback(async (params) => {
        try {
            return await VenuesCheckinsMessageService.postRequest(params);
        } catch (e) {
            console.log('fetchVenueCheckinsMessage', e.success)
        }
    }, []);

    const fetchUserConnectionMessage = useCallback(async (params) => {
        try {
            return await UsersConnectionMessageService.postRequest(params);
        } catch (e) {
            console.log('fetchUserConnectionMessage', e)
        }
    }, []);

    const fetchUserDirectMessage = useCallback(async (params) => {
        try {
            return await UserDirectService.postRequest(params);
        } catch (e) {
            console.log('fetchUserDirectMessage', e.success)
        }
    }, []);

    const handleChangeSubject = useCallback((event) => {
        const {value} = event.target;
        setSubject(value);
    }, []);

    const handleChangeTitle = useCallback((event) => {
        const {value} = event.target;
        setFormTitle(value);
    }, []);

    const handleChangeMessage = useCallback((event) => {
        const {value} = event.target;
        setFormMessage(value);
    }, []);

    const renderCountOfUsers = useCallback(() => {
        if (activeTab === 0) {
            if (tab === 'All time') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.location.allTime.users}
                    </p>
                );
            }
            if (tab === 'Last Week') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.location.lastWeek.users}
                    </p>
                );
            }
            if (tab === 'Last Month') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.location.lastMonth.users}
                    </p>
                );
            }
            if (tab === 'Custom') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.location.custom.users}
                    </p>
                );
            }
        }
        if (activeTab === 0 && isEmpty(location)) {
            if (tab === 'All time') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.location.allTime.users}
                    </p>
                );
            }
            if (tab === 'Last Week') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.location.lastWeek.users}
                    </p>
                );
            }
            if (tab === 'Last Month') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.location.lastMonth.users}
                    </p>
                );
            }
            if (tab === 'Custom') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.location.custom.users}
                    </p>
                );
            }
        }
        if (activeTab === 1) {
            if (tab === 'All time') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.venue.allTime.users}
                    </p>
                );
            }
            if (tab === 'Last Week') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.venue.lastWeek.users}
                    </p>
                );
            }
            if (tab === 'Last Month') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.venue.lastMonth.users}
                    </p>
                );
            }
            if (tab === 'Custom') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.venue.custom.users}
                    </p>
                );
            }
        }
        if (activeTab === 2) {
            if (tab === 'All time') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.user.allTime.contacts}
                    </p>
                );
            }
            if (tab === 'Last Week') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.user.lastWeek.contacts}
                    </p>
                );
            }
            if (tab === 'Last Month') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.user.lastMonth.contacts}
                    </p>
                );
            }
            if (tab === 'Custom') {
                return (
                    <p className='sendMessage-item-subTitle'>
                        {statistic.venue && statistic.user.custom.contacts}
                    </p>
                );
            }
        }

    }, [activeTab, location, statistic, tab]);

    const onSubmitHandler = useCallback((e) => {
        e.preventDefault();

        if (activeTab === 0) {
            fetchLocationCheckinsMessage({
                timeZone: timeZone,
                city: location.city && location.city,
                state: location.state && location.state,
                country: location.country && location.country,
                ...dateRange,
                notificationData: {
                    title: formTitle,
                    subject: subject,
                    message: formMessage
                }
            })
                .then(response => {
                        if (response) {
                            console.log('good');
                        }
                    }
                )
        }
        if (activeTab === 1) {
            fetchVenueCheckinsMessage({
                timeZone: timeZone,
                extId: venue.id,
                ...dateRange,
                notificationData: {
                    title: formTitle,
                    subject: subject,
                    message: formMessage
                }
            })
                .then(response => {
                        if (response) {
                            console.log('good');
                        }
                    }
                )
        }
        if (activeTab === 2 && !flagDirect) {
            fetchUserConnectionMessage({
                timeZone: timeZone,
                qrCode: user.qrCode && user.qrCode,
                phone: user.phone && user.phone.replace(/[^\d+]+/g, ''),
                ...dateRange,
                notificationData: {
                    title: formTitle,
                    subject: subject,
                    message: formMessage
                }
            })
                .then(response => {
                        if (response) {
                            console.log('good');
                        }
                    }
                )
        }
        if (activeTab === 2 && flagDirect) {
            console.log('direct message');
            fetchUserDirectMessage({
                timeZone: timeZone,
                qrCode: user.qrCode && user.qrCode,
                phone: user.phone && user.phone.replace(/[^\d+]+/g, ''),
                notificationData: {
                    title: formTitle,
                    subject: subject,
                    message: formMessage
                }
            })
                .then(response => {
                        if (response) {
                            console.log('good');
                        }
                    }
                )
        }
    }, [activeTab, dateRange, fetchLocationCheckinsMessage, fetchUserConnectionMessage, fetchUserDirectMessage, fetchVenueCheckinsMessage, flagDirect, formMessage, formTitle, location.city, location.country, location.state, subject, user.phone, user.qrCode, venue.id]);

    return (
        <Dialog onClose={handleClickOpen} className='sendMessage' aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClickOpen}>
                <IconButton aria-label="close" className='searchResult-windowTitle__close'
                            onClick={handleClickOpen}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className='sendMessage-content'>
                <h2 className='sendMessage-title'>Send Message</h2>
                <p className='sendMessage-subTitle'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum sed
                    quis arcu, aliquam enim diam pharetra, imperdiet. Arcu, nunc, facilisis sit placerat in eu nunc,
                    at.</p>
                <div className='sendMessage-item'>
                    <div className='sendMessage-item-info' style={flagDirect ? {width: '100%'} : null }>
                        <div className='sendMessage-item-img'><Skeleton variant='circle' width={42} height={42}/></div>
                        <div>
                            <p className='sendMessage-item-title'>
                                {location ? location.state : null}
                                {venue ? venue.name : null}
                                {user ? user.qrCode || user.phone : null}
                            </p>
                            <p className='sendMessage-item-subTitle'>
                                {location ? location.city && location.city : null}
                                {!isEmpty(venue) ? `${venue.location.country}, ${venue.location.city}, ${venue.location.address}` : null}
                            </p>
                        </div>
                    </div>
                    {!flagDirect ? <>
                    <div className='sendMessage-item-period'>
                        <div className='sendMessage-item-img'><img src={User} alt={User}/></div>
                        <div>
                            <p className='sendMessage-item-title'>Time:</p>
                            <p className='sendMessage-item-subTitle'>{tab}</p>
                        </div>
                    </div>
                    <div className='sendMessage-item-count'>
                        <div className='sendMessage-item-img'><img src={Time} alt={Time}/></div>
                        <div>
                            <p className='sendMessage-item-title'>Count users:</p>
                            {renderCountOfUsers()}
                        </div>
                    </div>
                    </>: null}
                </div>
                <form className='sendMessage-form' onSubmit={onSubmitHandler}>
                    <FormControl fullWidth className='sendMessage-form-input' variant='outlined'>
                        <InputLabel>Subject</InputLabel>
                        <Select

                            label='Subject'
                            onChange={handleChangeSubject}
                            value={subject}
                        >
                            <MenuItem value='' label=''/>
                            <MenuItem value='Informational'>Informational</MenuItem>
                            <MenuItem value='Informational2'>Informational 2</MenuItem>
                            <MenuItem value='Informational3'>Informational 3</MenuItem>
                            <MenuItem value='Informational4'>Informational 4</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        className='sendMessage-form-input'
                        variant='outlined'
                        label='Enter Title'
                        onChange={handleChangeTitle}
                        value={formTitle}
                    />
                    <TextField
                        className='sendMessage-form-input'
                        variant='outlined'
                        onChange={handleChangeMessage}
                        label="Message"
                        value={formMessage}
                    />
                    <button className='modalQr-generateQrButton button-blue center'
                            type='submit'>
                        <img src={Mail} alt={Mail}/>
                        <span>Send Messages</span>
                    </button>
                </form>
            </DialogContent>
        </Dialog>
    )
}