import React, {useCallback, useEffect, useState} from "react";
import './Messages.scss';
import {useLocation} from "react-router-dom";
import { TablePagination, CircularProgress, OutlinedInput, InputLabel, FormControl, IconButton, InputAdornment} from "@material-ui/core";
import FilterIcon from '../../../static/img/filter.png';
import SearchIcon from '../../../static/img/search.png';
import Pagination from "@material-ui/lab/Pagination";
import NotificationStatisticsService from "../../../services/NotificationStatisticsService";
import {isEmpty} from "lodash";
import {MessageListItem} from "./MessageListItem/MessageListItem";

export const Messages = () => {
    const location = useLocation();
    const body = document.body;
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchMailInput, setSearchMailInput] = useState('');
    const [messageList, setMessageList] = useState([]);
    const [countPages, setCountPages] = useState();

    const fetchNotificaton = useCallback(async (params) => {
        try {
            return await NotificationStatisticsService.getRequest(params);
        } catch (e) {
            console.log('fetchStatistics', e.message)
        }
    }, []);

    useEffect(() => {
        if (location.pathname === '/messages') {
            body.setAttribute('class', 'admin');
        }
    }, [body, location]);

    useEffect(() => {
        fetchNotificaton({limit: rowsPerPage, offset: (page - 1) * rowsPerPage})
            .then((response) => {
                console.log(response);
                    if (response.statistic) {
                        setMessageList(response.statistic);
                    }
                    if (response.count) {
                        setCountPages(response.count)
                    }
                }
            )
    }, [page, fetchNotificaton, rowsPerPage])

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);

    const searchMailHandler = useCallback((event) => {
        const {value} = event.target;
        setSearchMailInput(value)
    }, []);

    const renderMessageItem = useCallback(() => {
        if (isEmpty(messageList)) {
            return (
                <div style={{margin: '30px auto'}}>
                    <CircularProgress style={{color: '#0f9df5'}}/>
                </div>
            )
        }
        return messageList.map((item) => {
                return (
                    <MessageListItem
                        item={item}
                        key={item.notificationId}
                    />
                )
            }
        );
    }, [messageList])

    return (
        <div className='messageList'>
            <div className='messageList-header'>
                <div>
                    <h1 className='messageList-title'>Messages</h1>
                </div>
                <div>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="filled-adornment-password">Search mail</InputLabel>
                        <OutlinedInput
                            label='Search mail'
                            value={searchMailInput}
                            onChange={searchMailHandler}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                    >
                                        <img src={SearchIcon} alt={SearchIcon}/>
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <button className='messageList-filterIconButton'><img className='center' src={FilterIcon}
                                                                          alt={FilterIcon}/></button>
                </div>
            </div>
            <div className='messageList-body'>
                <div className='messageList-bodyHeader'>
                    <div style={{width: '222px'}}><span>Name</span></div>
                    <div style={{width: '80px'}}><span>Date</span></div>
                    <div style={{width: '256px'}}><span>Venue/User/Region</span></div>
                    <div style={{width: '100px'}}><span>Received</span></div>
                    <div style={{width: '100px'}}><span>Read</span></div>
                </div>
                <div className='messageList-iBox'>
                    {renderMessageItem()}
                </div>
                <div className='messageList-navigation'>
                    <div className='messageList-navigation__pages' style={{width: '380px'}}>
                        <Pagination
                            page={page}
                            count={Math.ceil(countPages/rowsPerPage)}
                            onChange={handleChangePage}
                            shape="rounded"/>
                    </div>
                    <div className='messageList-navigation__perPage'>
                        <TablePagination
                            component="div"
                            count={100}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}