import React, {useCallback, useEffect, useRef, useState} from "react";
import moment from "moment";
import {isEmpty} from "lodash";
import './ItemInformation.scss'
import {useReactToPrint} from "react-to-print";
import QrImage from "../../../../static/img/QR.png";
import {ItemDetails} from "./ItemDetails/ItemDetails";
import {StatisticBox} from "./StatisticPanel/StatisticBox";
import {ModalQR} from "../../../components/ModalQR/ModalQR";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {UserSendMessage} from "./UserSendMessage/UserSendMessage";
import VenueStatistics from "../../../../services/VenueStatistics";
import UsersStatistics from "../../../../services/UsersStatistics";
import DataPicker from "../../../components/DataPicker/DataPicker";
import {GeographicalArea} from "../GeographicalArea/GeographicalArea";
import {PDFTemplate} from "../../../components/PDFTemplate/PDFTemplate";
import LocationStatistics from "../../../../services/LocationStatistics";
import GeographicalAreaServices from "../../../../services/GeographicalAreaServices";

const nowDate = moment().format('MM-DD-YYYY');
const lastWeek = moment().subtract(7, 'd').format('MM-DD-YYYY');
const lastMonth = moment().subtract(1, 'M').format('MM-DD-YYYY');

const initialStatisticFields = {
    allTime: {},
    lastMonth: {},
    lastWeek: {},
    custom: {},
};

const initialStatistic = {
    location: {...initialStatisticFields},
    venue: {...initialStatisticFields},
    user: {...initialStatisticFields},
};

export const ItemInformation = ({activeTab, location, venue, user, clearForm}) => {
    const componentRef = useRef();
    const [open, setOpen] = useState(false);
    const [qrURL, setQrURL] = useState('')
    const [geoData, setGeoData] = useState({});
    const [tab, setTab] = useState('All time');
    const [notFound, setNotFound] = useState(false);
    const [customData, setCustomData] = useState({});
    const [openPicker, setOpenPicker] = useState(true);
    const [statistic, setStatistic] = useState(initialStatistic);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [flagDirect, setFlagDirect] = useState(false);
    const [statisticSkeleton, setStatisticSkeleton] = useState(false);

    const handleClickOpen = useCallback(() => {
        setOpen(!open);
    },[open]);

    const getFlagDirectMessage = useCallback(() => {
        setFlagDirect(true);
        handleClickOpen();
    }, [handleClickOpen]);

    const dialogWindowHandler = useCallback(() => {
        setDialogOpen(prevState => !prevState);
    }, []);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${!isEmpty(venue) && venue.name.replace(/ /g, '_')}.pdf`,
    });

    useEffect(() => {
        if (!dialogOpen) {
            setQrURL('');
        }
        if (!isEmpty(qrURL) && dialogOpen) {
            return handlePrint();
        }
        if (open === false) {
            setFlagDirect(false);
        }
    }, [handlePrint, qrURL, dialogOpen, open]);

    const fetchVenueStatistic = useCallback(async (params) => {
        try {
            return await VenueStatistics.getRequest(params);
        } catch (e) {
            console.log('fetchStatistics', e.message)
        }
    }, []);

    const fetchLocationStatistic = useCallback(async (params) => {
        try {
            return await LocationStatistics.getRequest(params);
        } catch (e) {
            console.log('fetchStatistics', e.message)
        }
    }, []);

    const fetchUserStatistics = useCallback(async (params) => {
        try {
            return await UsersStatistics.getRequest(params);
        } catch (e) {
            console.log('fetchStatistics', e.message)
        }
    }, []);

    const fetchGeoData = useCallback( async (params) => {
        try {
            return await GeographicalAreaServices.getRequest(params);
        } catch (e) {
            console.log('fetchGeoData', e.message);
        }
    }, []);

    const handleTabs = useCallback((e) => {
        const {value} = e.target;
        setTab(value);
        if (tab === 'Custom') {
            setOpenPicker(!openPicker);
        }
    }, [openPicker, tab]);

    useEffect(() => {
        setTab('All time');
    }, [setTab, clearForm, location, venue, user]);

    useEffect(() => {
        if (activeTab === 0  && isEmpty(location)) {
            if (tab === 'All time') {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchLocationStatistic({...location})
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                location: {
                                    ...statistic.location,
                                    allTime: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Last Week') {
                setStatisticSkeleton(true);
                fetchLocationStatistic({
                    ...location,
                    fromDate: lastWeek,
                    toDate: nowDate
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                location: {
                                    ...statistic.location,
                                    lastWeek: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Last Month') {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchLocationStatistic({
                    ...location,
                    fromDate: lastMonth,
                    toDate: nowDate
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                location: {
                                    ...statistic.location,
                                    lastMonth: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Custom' && !isEmpty(customData.startDate) && !isEmpty(customData.endDate)) {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchLocationStatistic({
                    ...location,
                    fromDate: moment(customData.startDate).format('MM-DD-YYYY'),
                    toDate: moment(customData.endDate).format('MM-DD-YYYY')
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                location: {
                                    ...statistic.location,
                                    custom: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
        }
    }, [activeTab, customData, fetchLocationStatistic, location, tab]);

    useEffect(() => {
        if (activeTab === 0 && !isEmpty(location)) {
            if (tab === 'All time') {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchLocationStatistic({
                    ...location,
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                location: {
                                    ...statistic.location,
                                    allTime: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Last Week') {
                setStatisticSkeleton(true);
                fetchLocationStatistic({
                    ...location,
                    fromDate: lastWeek,
                    toDate: nowDate
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                location: {
                                    ...statistic.location,
                                    lastWeek: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Last Month') {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchLocationStatistic({
                    ...location,
                    fromDate: lastMonth,
                    toDate: nowDate
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                location: {
                                    ...statistic.location,
                                    lastMonth: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Custom'  && !isEmpty(customData.startDate) && !isEmpty(customData.endDate)) {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchLocationStatistic({
                    ...location,
                    fromDate: moment(customData.startDate).format('MM-DD-YYYY'),
                    toDate: moment(customData.endDate).format('MM-DD-YYYY')
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                location: {
                                    ...statistic.location,
                                    custom: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
        }
    }, [activeTab, customData.startDate, customData.endDate, fetchLocationStatistic, location, tab]);

    useEffect(() => {
        if (activeTab === 1 && !isEmpty(venue)) {
            if (tab === 'All time') {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchVenueStatistic({
                    extId: venue.id
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                venue: {
                                    ...statistic.venue,
                                    allTime: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Last Week') {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchVenueStatistic({
                    extId: venue.id,
                    fromDate: lastWeek,
                    toDate: nowDate
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                venue: {
                                    ...statistic.venue,
                                    lastWeek: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Last Month') {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchVenueStatistic({
                    extId: venue.id,
                    fromDate: lastMonth,
                    toDate: nowDate
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                venue: {
                                    ...statistic.venue,
                                    lastMonth: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
            if (tab === 'Custom'  && !isEmpty(customData.startDate) && !isEmpty(customData.endDate)) {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchVenueStatistic({
                    extId: venue.id,
                    fromDate: moment(customData.startDate).format('MM-DD-YYYY'),
                    toDate: moment(customData.endDate).format('MM-DD-YYYY')
                })
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                venue: {
                                    ...statistic.venue,
                                    custom: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {setNotFound(true);}
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {return null;}
                    })
                    .catch(() => {return null;})
            }
        }
    }, [venue, fetchVenueStatistic, activeTab, tab, customData.startDate, customData.endDate]);

    useEffect(() => {
        if (activeTab === 2 && !isEmpty(user)) {
            if (tab === 'All time') {
                setStatisticSkeleton(true);
                fetchUserStatistics({qrCode: user.qrCode, phone: user.phone && user.phone.replace(/[^\d+]+/g, '')})
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                user: {
                                    ...statistic.user,
                                    allTime: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {setNotFound(true);}
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {return null;}
                    })
                    .catch(() => {return null;})
            }
            if (tab === 'Last Week') {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchUserStatistics({qrCode: user.qrCode, phone: user.phone && user.phone.replace(/[^\d+]+/g, ''), fromDate: lastWeek, toDate: nowDate})
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                user: {
                                    ...statistic.user,
                                    lastWeek: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {return null;}
                    })
                    .catch(() => {return null;})
            }
            if (tab === 'Last Month') {
                setNotFound(false);
                setStatisticSkeleton(true);
                fetchUserStatistics({qrCode: user.qrCode, phone: user.phone && user.phone.replace(/[^\d+]+/g, ''), fromDate: lastMonth, toDate: nowDate})
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                user: {
                                    ...statistic.user,
                                    lastMonth: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {return null;}
                    })
                    .catch(() => {return null;})
            }
            if (tab === 'Custom'  && !isEmpty(customData.startDate) && !isEmpty(customData.endDate)) {
                setStatisticSkeleton(true);
                setNotFound(false);
                fetchUserStatistics({qrCode: user.qrCode, phone: user.phone && user.phone.replace(/[^\d+]+/g, ''), fromDate: moment(customData.startDate).format('MM-DD-YYYY'), toDate: moment(customData.endDate).format('MM-DD-YYYY')})
                    .then((response) => {
                        if (response.statistics) {
                            setStatistic(statistic => ({
                                ...statistic,
                                user: {
                                    ...statistic.user,
                                    custom: response.statistics,
                                },
                            }));
                        }
                        if (response.message) {
                            setNotFound(true);
                        }
                        setStatisticSkeleton(false);
                        if (response === 'undefined') {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null;
                    })
            }
        }
    }, [activeTab, customData.startDate, customData.endDate, fetchUserStatistics, tab, user]);

    useEffect(() => {
        if (activeTab === 0 && isEmpty(location)) {
            if (tab === 'All time') {
                fetchGeoData({})
                    .then((response) => {
                        if (response) {
                            setGeoData(response);
                        }
                        if (response.message) {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null
                    })
            }
            if (tab === 'Last Week') {
                fetchGeoData({
                    fromDate: lastWeek,
                    toDate: nowDate,
                })
                    .then((response) => {
                        if (response) {
                            setGeoData(response);
                        }
                        if (response.message) {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null
                    })
            }
            if (tab === 'Last Month') {
                fetchGeoData({
                    fromDate: lastMonth,
                    toDate: nowDate,
                })
                    .then((response) => {
                        if (response) {setGeoData(response);}
                        if (response.message) {return null;}
                    })
                    .catch(() => {return null;})
            }
            if (tab === 'Custom' && !isEmpty(customData.startDate) && !isEmpty(customData.endDate)) {
                fetchGeoData({
                    fromDate: moment(customData.startDate).format('MM-DD-YYYY'),
                    toDate: moment(customData.endDate).format('MM-DD-YYYY')
                })
                    .then((response) => {
                        if (response) {
                            setGeoData(response);
                        }
                        if (response.message) {
                            return null;
                        }
                    })
                    .catch(() => {
                        return null
                    })
            }
        }
    }, [activeTab, customData.endDate, customData.startDate, fetchGeoData, location, tab]);

    const renderGeographicalArea = useCallback(() => {
        if (!isEmpty(location) && !isEmpty(venue) && !isEmpty(user)) {
            return null;
        }
        if (activeTab === 0 && !isEmpty(geoData)) {
            return (
                <GeographicalArea
                    geoData={geoData}
                />
            )
        }
    }, [activeTab, geoData, location, user, venue]);

    return (
        <>
            <div className='info'>
                <ItemDetails user={user} location={location} venue={venue} getFlagDirectMessage={getFlagDirectMessage}/>
                <div className='info__actions'>
                    <div className='info__actions-statistic'>
                        <ToggleButtonGroup size="large" value={tab} exclusive onChange={handleTabs}>
                            <ToggleButton value='All time' onChange={handleTabs}>All time</ToggleButton>
                            <ToggleButton value='Last Week' onChange={handleTabs}>Last Week</ToggleButton>
                            <ToggleButton value='Last Month' onChange={handleTabs}>Last Month</ToggleButton>
                            <ToggleButton value='Custom' onChange={handleTabs}>Custom</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {isEmpty(venue) ? null :
                        <div className='info__actions-generateQR'>
                            <button className='modalQr-generateQrButton button-blue' onClick={dialogWindowHandler}>
                                <img src={QrImage} alt={QrImage}/><span>Generate QR-code</span>
                            </button>
                            <ModalQR open={dialogOpen} venue={venue} onClose={dialogWindowHandler} onChangeQr={setQrURL}/>
                            <div style={{display: 'none'}}><PDFTemplate pdfRef={componentRef} qrURL={qrURL} venue={venue}/></div>
                        </div>
                    }
                </div>
                <div className='info__statistics'>
                    <div className='info__statistics-data'>
                        <h3 className='info__statistics-data-title'>
                            {(activeTab === 0 && isEmpty(location)) && 'All world'  }
                            {((activeTab === 0 && !isEmpty(location)) || (activeTab === 1 && !isEmpty(venue)) || (activeTab === 2 && !isEmpty(user))) && 'Statistics' }
                        </h3>
                        <p className='info__statistics-data-period'>{tab}</p>
                        {tab === 'Custom' ?
                            <DataPicker numberOfMonths={1} autoFocus={false} setCustomData={setCustomData}/> : null}
                        <StatisticBox
                            statistic={statistic} activeTab={activeTab} tab={tab}
                            statisticSkeleton={statisticSkeleton} notFound={notFound}
                        />
                        <p className='info__statistics-data-attention'>* Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit. Rutrum sed quis arcu, aliquam enim diam pharetra, imperdiet. Arcu,
                            nunc, facilisis sit placerat in eu nunc, at.</p>
                    </div>
                    <UserSendMessage
                        tab={tab} user={user} open={open} venue={venue}
                        nowDate={nowDate} location={location} lastWeek={lastWeek}
                        activeTab={activeTab} statistic={statistic} lastMonth={lastMonth}
                        customDate={customData} flagDirect={flagDirect} handleClickOpen={handleClickOpen}
                />
                </div>
            </div>
            {renderGeographicalArea()}
        </>
    )
}