import {USER_URLS} from "../constants/urls";
import CRUDService from "./CRUDService";

class AutocompleteService extends CRUDService {
    constructor() {
        super(USER_URLS.VENUES_AUTOCOMPLETE)
    }

}

export default new AutocompleteService();