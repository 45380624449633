import React from "react";

export const StatisticPanel = ({label, data, loading}) => {

    return (
        <div className={`info__statistics-item ${loading ? "info__statistics-loading" : null}`}>
            <p className='info__statistics-item-title'>{label}</p>
            <span
                className='info__statistics-item-count'>{data}</span>
        </div>
    )
}