import React from "react";
import './HomeMainContent.scss'
import Business from "../../../static/img/imageBusiness.svg";
import Individuals from "../../../static/img/imageIndividuals.svg";
import {WhyOver} from "./WhyOver/WhyOver";
import {SubscribeForm} from "../SubscribeForm/SubscribeForm";

export const HomeMainContent = () => {
    return (
        <>
            <div className='homeMain'>
                <h1 className='homeMain-headLine'>Why use TruLink?</h1>
                <div className='homeMain-blocks'>
                    <div className='homeMain-blocks__item'>
                        <div className='homeMain-blocks__item-image'>
                            <img className='homeMain-blocks__img' src={Business} alt="Business"/>
                        </div>
                        <h2 className='homeMain-blocks__title'>
                            For businesses
                        </h2>
                        <h4 className='homeMain-blocks__subtitle'>
                            Have something to share with others?
                        </h4>
                        <p className='homeMain-blocks__paragraph'>
                            Have something to share with others? These days, digital connects matter more than ever… no
                            matter
                            what product or service you offer! One scan of a QR code keeps you in touch with an
                            ever-growing
                            number of patrons and followers. Insight, announcements, promotions; encouraging future
                            interactions
                            is easier than ever with the powerful Trulink platform by your side.
                        </p>
                        <a className='button-blue' href='/for-merchants'>
                            <span>Learn more</span>
                        </a>
                    </div>
                    <div className='homeMain-blocks__item'>
                        <div className='homeMain-blocks__item-image'>
                            <img className='homeMain-blocks__img' src={Individuals} alt="Individuals"/>
                        </div>
                        <h2 className='homeMain-blocks__title'>
                            For individuals
                        </h2>
                        <h4 className='homeMain-blocks__subtitle'>
                            Want to supplement moments in the real world?
                        </h4>
                        <p className='homeMain-blocks__paragraph'>
                            Want to supplement moments in the real world? Trulink’s 21st-century approach forges strong
                            relationships with new friends and acquaintances for years to come! One QR scan with any
                            device
                            safely links you to the places you visit and the people you visit throughout life. Seamless,
                            worthwhile interactions with those along the way will inspire growth in all directions!
                        </p>
                        <div className='homeMain-blocks__os'>
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d)">
                                    <ellipse cx="40" cy="33.5327" rx="25" ry="24.5327" fill="#1890FF"/>
                                    <path
                                        d="M44.07 23.4758C45.0245 22.2666 45.7481 20.5576 45.4864 18.8131C43.9263 18.9195 42.1027 19.899 41.0387 21.1757C40.0688 22.3325 39.2716 24.0534 39.583 25.7236C41.2885 25.7759 43.0487 24.7728 44.07 23.4758Z"
                                        fill="white"/>
                                    <path
                                        d="M52.5 39.8855C47.5357 38.0329 46.7403 31.1073 51.6532 28.4491C50.1547 26.605 48.0489 25.536 46.0611 25.536C43.4353 25.536 42.3251 26.7688 40.5016 26.7688C38.6216 26.7688 37.1932 25.5394 34.9232 25.5394C32.6925 25.5394 30.3181 26.8769 28.8128 29.1635C26.6967 32.384 27.0576 38.4382 30.4892 43.5957C31.7157 45.4416 33.3545 47.5154 35.4979 47.534C37.4053 47.5525 37.9442 46.3332 40.5289 46.3197C43.1137 46.3045 43.6047 47.5491 45.5086 47.5289C47.6538 47.512 49.3832 45.2136 50.6097 43.3678C51.489 42.0454 51.8175 41.3784 52.5 39.8855Z"
                                        fill="white"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d" x="0" y="0" width="80" height="79.0653"
                                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                        <feOffset dy="6"/>
                                        <feGaussianBlur stdDeviation="7.5"/>
                                        <feColorMatrix type="matrix"
                                                       values="0 0 0 0 0.172549 0 0 0 0 0.380392 0 0 0 0 0.603922 0 0 0 0.2 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                                 result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d)">
                                    <path
                                        d="M40 58.0653C26.1929 58.0653 15 47.0817 15 33.5327C15 19.9836 26.1929 9 40 9C53.8071 9 65 19.9836 65 33.5327C65 47.0817 53.8071 58.0653 40 58.0653Z"
                                        fill="#1890FF"/>
                                    <path
                                        d="M43.9129 21.9781L44.2305 21.5199L44.5481 21.0682L45.2558 20.0527C45.3438 19.9274 45.3076 19.7593 45.1764 19.6769C45.0469 19.5928 44.8692 19.6274 44.7846 19.7527L43.7041 21.2973L43.3796 21.7622C42.3526 21.3814 41.2082 21.1687 40 21.1687C38.7935 21.1687 37.6474 21.3814 36.6204 21.7622L36.2976 21.2973L35.9783 20.8407L35.2189 19.7527C35.1308 19.6274 34.9548 19.5944 34.8236 19.6769C34.6941 19.7593 34.6579 19.9274 34.7442 20.0527L35.4519 21.0682L35.7695 21.5199L36.0888 21.9781C33.6775 23.0513 32.0464 25.0839 32.0464 27.4099H47.9536C47.9536 25.0839 46.3225 23.0513 43.9129 21.9781Z"
                                        fill="white"/>
                                    <path
                                        d="M32.1689 28.4946H32.0464V40.433C32.0464 41.3809 32.8524 42.1524 33.8467 42.1524H35.1446C35.0998 42.2958 35.0756 42.4458 35.0756 42.604V46.0411C35.0756 46.9396 35.8402 47.6682 36.7809 47.6682C37.7216 47.6682 38.4863 46.9396 38.4863 46.0411V42.604C38.4863 42.4458 38.4604 42.2958 38.4172 42.1524H41.5828C41.5396 42.2958 41.5155 42.4458 41.5155 42.604V46.0411C41.5155 46.9396 42.2784 47.6682 43.2191 47.6682C44.1615 47.6682 44.9261 46.9396 44.9261 46.0411V42.604C44.9261 42.4458 44.9002 42.2958 44.8553 42.1524H46.1551C47.1493 42.1524 47.9536 41.3809 47.9536 40.433V28.4946H32.1689Z"
                                        fill="white"/>
                                    <path
                                        d="M29.2053 28.4946C28.2629 28.4946 27.5 29.2233 27.5 30.1217V37.0865C27.5 37.985 28.2629 38.7136 29.2053 38.7136C30.146 38.7136 30.9089 37.985 30.9089 37.0865V30.1217C30.9089 29.2233 30.146 28.4946 29.2053 28.4946Z"
                                        fill="white"/>
                                    <path
                                        d="M50.7964 28.4946C49.854 28.4946 49.0911 29.2233 49.0911 30.1217V37.0865C49.0911 37.985 49.854 38.7136 50.7964 38.7136C51.7371 38.7136 52.5 37.985 52.5 37.0865V30.1217C52.5 29.2233 51.7371 28.4946 50.7964 28.4946Z"
                                        fill="white"/>
                                    <path
                                        d="M36.5911 25.2387C36.1199 25.2387 35.7384 24.8744 35.7384 24.4244C35.7384 23.9744 36.1199 23.6117 36.5911 23.6117C37.0623 23.6117 37.4437 23.9744 37.4437 24.4244C37.4437 24.8744 37.0623 25.2387 36.5911 25.2387Z"
                                        fill="#1890FF"/>
                                    <path
                                        d="M43.4089 25.2387C42.9377 25.2387 42.5563 24.8744 42.5563 24.4244C42.5563 23.9744 42.9377 23.6117 43.4089 23.6117C43.8801 23.6117 44.2616 23.9744 44.2616 24.4244C44.2616 24.8744 43.8801 25.2387 43.4089 25.2387Z"
                                        fill="#1890FF"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d" x="0" y="0" width="80" height="79.0653"
                                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                        <feOffset dy="6"/>
                                        <feGaussianBlur stdDeviation="7.5"/>
                                        <feColorMatrix type="matrix"
                                                       values="0 0 0 0 0.172549 0 0 0 0 0.380392 0 0 0 0 0.603922 0 0 0 0.2 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                                 result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <WhyOver/>
            <SubscribeForm label='Home page'/>
        </>
    )
}