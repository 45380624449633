import React, {useCallback} from "react";
import './GeographicalArea.scss';
import {GeographicalItem} from "./GeographicalItem/GeographicalItem";
import {isEmpty} from "lodash";

export const GeographicalArea = ({geoData}) => {
    const renderGeoItems = useCallback(() => {
        if (isEmpty(geoData)) {
            return null;
        }
        return geoData.map((item) => {
            return (
                <GeographicalItem
                    item={item}
                    key={item.country}
                />
            )
        })
    }, [geoData]);

    return (
        <div className='geographical'>
            <h3 className='geographical-title'>Geographical area</h3>
            <h5 className='geographical-subTitle'>* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum sed quis arcu</h5>
            <div className='geographical-container'>
                <div className='geographical-container__header'>
                    <div className='geographical-container__item'>
                        <span className='geographical-container__headerTitle'>Location</span>
                    </div>
                    <div className='geographical-container__item'>
                        <span className='geographical-container__headerTitle'>Check-ins</span>
                    </div>
                    <div className='geographical-container__item'>
                        <span className='geographical-container__headerTitle'>Scans</span>
                    </div>
                </div>
                {renderGeoItems()}
            </div>
        </div>
    )
}