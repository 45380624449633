import React, { Component } from "react";

import moment from "moment";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";

import { DateRangePicker } from "react-dates";
import { DateRangePickerPhrases } from "react-dates/esm/defaultPhrases";
import DateRangePickerShape from "react-dates/esm/shapes/DateRangePickerShape";

import {
    END_DATE,
    START_DATE,
    ANCHOR_LEFT,
    NAV_POSITION_TOP,
    HORIZONTAL_ORIENTATION,
} from "react-dates/constants";

import "./DataPicker.scss";

const propTypes = {
    // example props for the demo
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    stateDateWrapper: PropTypes.func,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,

    ...omit(DateRangePickerShape, [
        "startDate",
        "endDate",
        "onDatesChange",
        "focusedInput",
        "onFocusChange",
    ]),
};

const defaultProps = {
    // example props for the demo
    autoFocus: true,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,

    // input related props
    startDateId: START_DATE,
    startDatePlaceholderText: "Start Date",
    endDateId: END_DATE,
    endDatePlaceholderText: "End Date",
    disabled: false,
    required: false,
    screenReaderInputMessage: "",
    showClearDates: false,
    showDefaultInputIcon: false,
    customInputIcon: null,
    customArrowIcon: null,
    customCloseIcon: null,
    block: false,
    small: false,
    regular: false,

    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: HORIZONTAL_ORIENTATION,
    anchorDirection: ANCHOR_LEFT,
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: false,
    isRTL: false,

    // navigation related props
    navPosition: NAV_POSITION_TOP,
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
    onClose() {},

    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 1,
    enableOutsideDays: true,
    isDayBlocked: () => false,
    isOutsideRange: () => false,
    isDayHighlighted: () => false,

    // internationalization
    displayFormat: () => moment.localeData().longDateFormat("L"),
    monthFormat: "MMMM YYYY",
    phrases: DateRangePickerPhrases,

    stateDateWrapper: date => date,
};

class DateRangePickerWrapper extends Component {
    constructor(props) {
        super(props);
        let focusedInput = null;
        if (props.autoFocus) {
            focusedInput = START_DATE;
        } else if (props.autoFocusEndDate) {
            focusedInput = END_DATE;
        }

        this.state = {
            focusedInput,
            startDate: props.initialStartDate,
            endDate: props.initialEndDate,
        };

        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    onDatesChange({ startDate, endDate }) {
        const { stateDateWrapper, setCustomData } = this.props;
        this.setState({
            startDate: startDate && stateDateWrapper(startDate),
            endDate: endDate && stateDateWrapper(endDate),
        });
        setCustomData({
            startDate: startDate && stateDateWrapper(startDate),
            endDate: endDate && stateDateWrapper(endDate),
        });
    }

    onFocusChange(focusedInput) {
        this.setState({ focusedInput });
    }

    renderCalendarInfo = () => {
        const { startDate, endDate } = this.state;

        if (!startDate || !endDate) {
            return null;
        }

        const start = moment(startDate).format("D MMM");
        const end = moment(endDate).format("D MMM");

        return (
            <div className="date-picker__bottom-info">
                {start} - {end}
            </div>
        )
    }

    render() {



        const { focusedInput, startDate, endDate } = this.state;

        // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
        // example wrapper but are not props on the SingleDatePicker itself and
        // thus, have to be omitted.
        const props = omit(this.props, [
            "autoFocus",
            "autoFocusEndDate",
            "initialStartDate",
            "initialEndDate",
            "stateDateWrapper",
        ]);

        return (
            <div className="date-picker">
                <DateRangePicker
                    {...props}
                    calendarInfoPosition="bottom"
                    renderCalendarInfo={this.renderCalendarInfo}
                    onDatesChange={this.onDatesChange}
                    onFocusChange={this.onFocusChange}
                    focusedInput={focusedInput}
                    startDate={startDate}
                    endDate={endDate}
                    autoFocus
                    keepOpenOnDateSelect
                />
            </div>
        );
    }
}

DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default DateRangePickerWrapper;
