import React from "react";
import './Terms.scss'
import Helmet from "react-helmet";

export const Terms = () => {
    return (
        <div className='terms'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms of Use - Trulink</title>
            </Helmet>
            <h1 className='terms-title'>Terms of Use</h1>
            <h3 className='terms-subTitle'>Last Updated June 16, 2020</h3>

            <p className='terms-attention'>
                PLEASE READ THROUGH THE TERMS OF USE CAREFULLY AS THEY CONSTITUTE A BINDING LEGAL AGREEMENT BETWEEN YOU
                AND FWOLLO LLC.
            </p>
            <p>
                Dear user,<br/>
                Welcome to TruLink!
            </p>
            <p>TruLink provides discretionary opt-in services via our mobile application and related websites
                (collectively, the <strong>“Platform”</strong>) that aid and enable users
                (<strong>“you”</strong>, <strong>“your”</strong>, <strong>“user”</strong> or <strong>“users”</strong>)
                in tracking
                locations where they have been and people they have come into contact with
                (the <strong>“Services”</strong>). These Terms of
                Use, as amended from time to time (the <strong>“Terms”</strong>) govern the use of TruLink by you, and
                constitute a complete
                and binding agreement between you and FWOLLO LLC, its parents, subsidiaries and affiliates
                (collectively,
                <strong>“we”</strong>, <strong>“our”</strong>, <strong>“us”</strong> or <strong>“TruLink”</strong>,
                together with you, the <strong>“Parties”</strong> and each, individually, a <strong>“Party”</strong>).
                By
                engaging the use of our Services, you agree that you have read and assent to all terms and provisions
                herein. Your access to and use of the Platform and the Services is conditioned on your assent to and
                compliance with these Terms. If you disagree with any part of these Terms, then you may not access the
                Platform and the Services.</p>
            <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the
                Platform and Services.</p>
            <p>Your access to and use of the Platform and Services is also conditioned on your acceptance of and
                compliance
                with our Privacy Policy. Our Privacy Policy describes our policies and procedures on the collection,
                use,
                and disclosure of your personal information when you use the Services and informs you about your privacy
                rights and how the law protects you. Please read our Privacy Policy carefully before using our Platform
                and
                Services. Our Privacy Policy is incorporated herein by this reference.</p>

            <p><strong>I. Term</strong></p>
            <p>Upon access to our Platform and use of the Services, you are bound by and subject to all the terms
                herein. <strong>Please read them carefully</strong>. These Terms may be edited, amended, modified,
                revised, added to, or
                reduced, from time to time, at our sole discretion. You shall be bound by any and all amendments,
                modifications or revisions to the Terms. Please note that we are under no obligation to notify you of
                any such changes to the Terms. You should therefore visit this page periodically to review the Terms.
                Each time you access the Platform and engage in the use of the Services, you are agreeing to be legally
                bound by the most updated Terms provided.</p>

            <p>We may terminate or suspend your access to the Services immediately, without prior notice or liability,
                with or without cause, at our sole discretion. Your right to use the Services will cease immediately
                upon termination.</p>

            <p><strong>II. Licenses and Limitations</strong></p>
            <p>While you remain in compliance with these Terms, we grant you a non–exclusive, non-sublicensable,
                non–transferable, revocable, limited license to access and use the Platform and the Services. You hereby
                agree that you are only to utilize the Services for your own use and benefit, and not to use the
                Services for any commercial purpose or financial gain. This license is discretionary and can be revoked
                or amended, with or without cause, at any time. Violation of the terms of this license will result in an
                automatic termination of your rights to use the Services and possible civil charges.</p>

            <p><strong>III. Services</strong></p>
            <p>Our mission is to help people to track their movements to take account of their habits, relationships,
                work and leisure life, exposures, connections and time management. TruLink’s Platform allows you to
                track your movement and take an account of the places you visit, at your sole discretion.</p>
            <p>We provide an interactive Platform for tracking the places and people that you have been in contact with,
                the duration of time that you spend at certain places, and the amount of time you use to travel.</p>

            <p><strong>A. User Profile and Account</strong></p>

            <p>In order to fully use the Platform and the Services, you must register for, and maintain an active user
                account (an “Account”). We require the following profile information from you during the process of
                registering your user Account:</p>

            <p>(i) your name, residential and email address, phone number, and age;<br/>
                (ii) your username and password which you are free to determine and select subject to minimal control
                restrictions;<br/>
                (iii) credit card and payment information; and<br/>
                (iv) such other information as is specified in our Privacy Policy.</p>

            <p>You may customize your profile by:<br/>
                (i) adding your photo or an avatar;<br/>
                (ii) changing your username or password;<br/>
                (iii) accepting notifications about places you have visited and/or repeat places you visit and/or places
                you have been in for a period of time you determine;<br/>
                (iv) determining information you want to share with other users of the Platform or third parties;
                and<br/>
                (v) making your profile public, considering the default setting is private;</p>

            <p><strong>B. Logging a Destination</strong></p>
            <p>Logging a destination is done in one of two ways. The first is using the comprehensive QR System.
                Participating retailers and businesses will have a QR code for you to scan that immediately inputs the
                information of your stay in that business upon entrance and exit. The second is an option for manual
                entry of the location you are visiting and the duration of your stay, which mainly applies where the
                location you are visiting is not a participator.</p>

            <p>Please note that you are solely responsible for the accuracy of any manual entries you make on the
                Platform.</p>

            <p><strong>C. Community Alerts</strong></p>
            <p>Alerts will be provided to us on certain occasions regarding places that you frequent or have visited
                recently, including but not limited to: (i) when there is a health code violation at a location you
                frequent; (ii) an infectious disease alert at a location for the time that you were at that location or
                for a location that you frequent; or (iii) a state of emergency around your current location. These
                alerts will be supplied solely at the discretion of TruLink, and TruLink has no obligation,
                responsibility, or duty to disclose any information through the alert system nor be privy to any
                information that may lead to an alert. Additionally, TruLink is not responsible for the accuracy or any
                due diligence of the information provided through the Services and disclaims all liability based on the
                information included in any and all alerts.</p>

            <p><strong>D. Duration Tracking</strong></p>
            <p>TruLink supplies summaries of where and how you spend your time through certain durations, compiling the
                data in easily accessible and viewable tables so you can structure and plan for your lifestyle.</p>

            <p><strong>E. App Connections</strong></p>
            <p>Mutual user “friend” services are provided that allow users to connect with other users of the Services
                and make certain information from said users’ Accounts available between them. You are welcome to opt-in
                and use these services alongside other users.</p>

            <p>Due to the fact that a hub of information is available upon opening the app, <strong>each time you open
                the Platform, you are using the Services</strong>.</p>


            <p><strong>IV. Representations and Warranties</strong></p>

            <p>By accessing and using the Platform and Services, you represent and warrant that:<br/>

                (i) You are at least 18 years old, have the legal capacity to agree, and do agree to these Terms;<br/>

                (ii) You will provide any proof of identity information we may reasonably request from you;<br/>

                (iii) You agree to comply with all applicable US federal, state or local law, and/or other national or
                international law that may apply to your jurisdiction;<br/>

                (iv) All personal or other information you provide or enter on the Platform shall be accurate and
                truthful;<br/>

                (v) You shall use the Platform only for lawful purposes and shall not sell your Account;<br/>

                (vi) You will ensure your personal information in your Account on the Platform and related access
                information such as your password and username, are kept confidential and secure; and<br/>

                (vii) You will avoid any conduct on the Platform that may impair its proper use.</p>


            <p><strong>V. Intellectual Property</strong></p>
            <p><strong>A. Proprietary Ownership Notice</strong></p>
            <p>Subject to any exceptions provided herein, TruLink and its contractual licensors own all right, title and
                interest in all patents, trademarks, service marks, trade names, copyrights, trade secrets, licenses
                (software or otherwise), information, processes and similar proprietary rights (collectively <strong>“Intellectual
                    Property”</strong>) related to the Platform, the Services and any content generated by us or the
                users.</p>

            <p>Neither these Terms nor your use of the Platform and the Services convey or grant to you any rights: (i)
                in or related to the Platform and/or the Services except for the limited license granted above; or (ii) to
                use                or reference in any manner TruLink’s company names, logos, product and service names, trademarks or
                services
                marks or those of our licensors.</p>

            <p><strong>B. Restrictions</strong></p>
            <p>You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the
                Platform or Services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license,
                lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or
                otherwise
                exploit the Platform or Services except as expressly permitted by us; (iii) decompile, reverse engineer
                or
                disassemble the Platform or Services except as may be permitted by applicable law; (iv) link to, mirror
                or
                frame any portion of the Platform or Services; (v) cause or launch any programs or scripts for the
                purpose
                of scraping, indexing, surveying, or otherwise data mining any portion of the Platform or Services or
                unduly
                burdening or hindering the operation and/or functionality of any aspect of the Platform or Services; or
                (vi)
                attempt to gain unauthorized access to or impair any aspect of the Platform or Services or related
                systems
                or networks thereto.</p>

            <p><strong>C. Complaints Notice</strong></p>
            TruLink endeavors to respect the intellectual property rights of users and third parties, just as we expect
            our intellectual property rights to be respected. If you believe or suspect that your intellectual property
            rights have been infringed upon by us, please contact TruLink’s Contact Agent: Thomson Ollunga LLP at
            <a className='link' href='mailto:info@thomsonollunga.com'> info@thomsonollunga.com</a>, for the matter to be addressed
            forthwith.

            <p><strong>VI. Disclaimer of Warranty and Limitation of Liability</strong></p>
            <p>
                You hereby agree to accept any and all risk associated with using our Services. You represent that you
                will
                use these Services in accordance with applicable law. We do not warrant that the Platform and/or the
                Services will be without error, interruption, or cancellation.</p>

            <p>FOR THE AVOIDANCE OF DOUBT, TRULINK EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESSED OR
                IMPLIED, IN CONNECTION WITH THE PLATFORM AND THE SERVICES IN THESE TERMS, INCLUDING, WITHOUT LIMITATION,
                THE
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. WE MAKE NO
                WARRANTY OR REPRESENTATION REGARDING (i) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PLATFORM
                AND
                SERVICES REGARDING THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH USE OF THE PLATFORM
                AND
                SERVICES, OR THAT THE PLATFORM ANDF SERVICES WILL MEET ANY OF YOUR REQUIREMENTS OR (ii) THE QUALITY OF
                ANY
                CONTENT, MATERIAL AND/OR DATA DOWNLOADED THROUGH, OR CONTENT, MATERIAL AND/OR DATA RECORDED, OR EDITED
                (AS
                APPLICABLE) WITH, THE USE OF THE PLATFORM AND SERVICES.<br/>
                USE OF THE PLATFORM AND SERVICES AND OF ANY CONTENT OR MATERIAL OBTAINED AND/OR DATA DOWNLOADED THROUGH,
                OR
                CONTENT, MATERIAL AND/OR DATA RECORDED, OR EDITED, AS APPLICABLE, WITH, THE PLATFORM AND SERVICES IS AT
                YOUR
                SOLE DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOU OR THIRD PARTIES
                RESULTING
                FROM THE USE OF THE PLATFORM AND SERVICES IN BREACH OF THESE TERMS. THE PLATFORM AND SERVICES HEREUNDER
                ARE
                PROVIDED “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS.”</p>


            <p>Any liability belonging to us and our subsidiaries or affiliates for any damages that you may incur
                through
                the use of the Services may not exceed the amount actually paid by you through the Services to us, and
                in
                any event shall not exceed the total of One Hundred Dollars (US$100.00).</p>

            <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE, ALONG WITH OUR PARENTS, SUBSIDIARIES AND
                AFFILIATES,
                SHALL NOT BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL LOSS, COSTS, CHARGES,
                EXPENSES
                OR DAMAGES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR (I) LOSS OF PROFITS, LOSS OF BUSINESS,
                CONTRACTS, GOODWILL, BUSINESS OPPORTUNITIES OR BUSINESS INTERRUPTION, (II) LOSS OF INCOME OR ANTICIPATED
                SAVINGS, (III) LOSS OR CORRUPTION OF DATA OR INFORMATION, (IV) PERSONAL INJURY, (V) PRIVACY VIOLATIONS
                ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF OR INABILITY TO USE THE PLATFORM AND/OR SERVICES, OR
                (VI)
                THIRD-PARTY SOFTWARE AND/OR THIRD-PARTY HARDWARE USED WITH THE PLATFORM AND SERVICES, OR OTHERWISE IN
                CONNECTION WITH ANY OF THE TERMS HEREIN), EVEN IF ADVISED OF THE POTENTIAL OF SUCH DAMAGES BY YOU.</p>

            <p>In the event that governing law does not allow the exclusion of express warranties, this provision will
                apply to the maximum extent possible under applicable law, and each Party’s liability will be limited to
                the
                maximum extent possible under the law.</p>


            <p><strong>VII. Indemnification</strong></p>
            <p>You shall indemnify, save, and hold harmless FWOLLO LLC, its parents, subsidiaries, affiliates, officers,
            directors, employees, agents and its third–party suppliers, licensors, and partners from any claims, losses,
            damages, liabilities, including legal fees and expenses, arising out of your use or misuse of the Services,
            any violation by you of these Terms, or any breach of the representations, warranties, and covenants made by
            you in these Terms. We reserve the right, at your expense, to assume the exclusive defense and control of
            any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these
            claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming
            aware of it. You agree that the provisions in this paragraph will survive any termination of your Account or
            of the Services.</p>

            <p><strong>VIII. Governing Law</strong></p>
                <p><strong>A. Applicable Law</strong></p>
        <p>These Terms shall be construed in accordance with the laws of the State of New York law, without regard to
            its conflicts of laws principles, and the obligations, rights and remedies of the Parties hereunder shall be
            determined in accordance with such laws.</p>

            <p><strong>B. Mediation and Binding Arbitration</strong></p>

            <p>If a dispute arises from or relates to these Terms or the breach thereof, and if the dispute cannot be
            settled through direct discussions, the Parties agree to endeavor first to settle the dispute by mediation
            administered by the American Arbitration Association under its Commercial Mediation Procedures before
            resorting to arbitration. The Parties further agree that any unresolved controversy or claim arising out of
            or relating to these Terms, or breach hereof, shall be settled by final binding arbitration administered by
            the American Arbitration Association in accordance with its Commercial Arbitration Rules and judgment on the
            award rendered by the arbitrators may be entered in any court having jurisdiction thereof.<br />
            Claims shall be heard by a panel of three (3) arbitrators. Within fifteen (15) days after the commencement
            of arbitration, each Party shall select one person to act as arbitrator and the two selected shall select a
            third arbitrator within ten (10) days of their appointment. If the arbitrators selected by the Parties are
            unable or fail to agree upon the third arbitrator, the third arbitrator shall be selected by the American
            Arbitration Association. The place of arbitration shall be New York City, New York. The arbitration shall be
            governed by the laws of the State of New York, without regard to its conflicts of laws principles. Hearings
            will take place pursuant to the standard procedures of the Commercial Arbitration Rules that contemplate in
            person hearings. The arbitrators will have no authority to award punitive or other damages not measured by
            the prevailing Party's actual damages, except as may be required by statute. The prevailing Party shall be
            entitled to an award of reasonable attorney fees. The award of the arbitrators shall be accompanied by a
            reasoned opinion. Except as may be required by law, neither a Party nor an arbitrator may disclose the
            existence, content, or results of any arbitration hereunder without the prior written consent of both
            Parties.</p>

            <p><strong> C. Trial Waiver</strong></p>
            <p>Each Party hereby irrevocably waives, to the fullest extent permitted by applicable law, any and all right
            to trial by jury in any legal proceeding arising out of or relating to these Terms or the transactions
                contemplated hereby.</p>

            <p><strong>D. Class Action Waiver</strong></p>
            <p>You hereby agree that all claims pursued against TruLink will be on an individual basis. To that end, you
            hereby waive your right to commence, to become a party to, or to remain a participant in, any group,
            representative, class, collective, or hybrid class/collective action in any court, arbitration proceeding,
            or any other forum, against TruLink. You agree that any claim by or against TruLink shall be heard in
            arbitration without joinder of parties or consolidation of such claim with any other person or entity’s
            claim, except as otherwise agreed to in writing by the Parties.</p>

            <p><strong>XI. Severability</strong></p>
                <p>In the event any term or provision of these Terms is found to be illegal, unenforceable, void or excessively
            broad, in whole or in part, as drafted, then the offending term or provision shall be constructed as valid
            and enforceable to the maximum extent permitted by law, and the balance of these Terms shall remain
            unaffected, in full force and effect, unless such a construction would materially affect the rights or
            obligations of either Party hereto.</p>

                <p><strong>XII. Waiver</strong></p>

                    <p>No failure by any Party to insist upon the strict performance of any covenant, duty, agreement or condition
            of these Terms or to exercise any right or remedy consequent upon a breach thereof shall constitute waiver
            of any such breach of any other covenant, duty, agreement or condition.</p>

                    <p><strong>XIII. Prevailing Language</strong></p>
                        <p>A reasonable translation of these Terms and the Privacy Policy is available upon request. If we provide you
            with a translation of the English language version, then you agree that the translation is provided for
            informational purposes only and does not modify the English language version. Any conflict between the
            English version and any such translation shall favor the English version.</p>

            <p><strong>XIV. Notice</strong></p>

            <p>All notices under these Terms shall be in writing, and shall be deemed given when personally delivered, sent
            by confirmed telecopy or other electronic means such as e-mail, or, five (5) days after being sent by
            prepaid certified or registered mail to the address of the Party to be given notice. Notices we provide to
            you, shall be sent to your most updated address provided in your Account by any of the means identified
            herein (i.e. delivery, telecopy, e-mail or mail). Notices to us can be sent to our most updated address
                provided in these Terms, and most preferably via e-mail at <a className='link' href='mailto:info@fwollo.com'>info@fwollo.com</a></p>

            <p><strong>XV. Termination</strong></p>

            <p>These Terms are, and are intended to be, a continuing agreement and shall remain in full force and effect.
            We may, in our sole discretion, terminate, change, suspend, disable, add to or discontinue any aspect of the
            Platform, the Services and these Terms at any time, and without liability to any user. We may restrict,
            suspend or terminate your use of or access to the Platform and the Services if we believe you are in breach
            of or are attempting to breach the Terms or applicable law, or for any other reason without notice or
            liability (including, for example, if we learn that you have provided us with false or misleading content,
            or interfered with other users or the administration of the Platform and the Services).<br />
            In the event of the termination of the Terms for any reason: (i) the license granted to you in the Terms
            will immediately terminate; and (ii) you must immediately cease all use of the Platform and Services, and
            destroy or erase all copies of the Platform in your possession or control. All of the sections of the Terms
            will survive any termination of the Terms. Any use of the Platform and Services after termination is
            unlicensed and is in violation of the copyright and other rights of TruLink. TruLink and its parents,
            subsidiaries and affiliates reserve the right to refuse service, terminate Accounts, remove or edit content
                or cancel Services in their sole discretion.</p>


            <p><strong>XVI. Assignment</strong></p>

            <p>All of our rights, interests and obligations under these Terms are assignable at our sole discretion without
            approval from you or any other party. You may not assign either these Terms or any of its rights, interests,
            or obligations hereunder without our prior written approval. Subject to the preceding sentence, this
            Agreement shall be binding upon and shall inure to the benefit of the Parties hereto and their respective
                successors and permitted assigns.</p>

            <p><strong>G. Entire Agreement.</strong></p>
            <p>These Terms, together with all exhibits, attachments, appendices and schedules hereto, constitutes the
            entire agreement and understanding of the Parties with respect to the subject matter hereof and supersedes
            any and all prior negotiations, correspondence, agreements, understandings duties or obligations, whether
            written or oral, between the Parties with respect to the subject matter hereof.</p>

            <p><strong>H. Further Inquiries</strong></p>
            <p>In case of any questions, comments, suggestions, requests or other inquiries about these Terms, our Platform
            and Services, please feel free to reach out to us via e-mail at <a className='link' href='mailto:info@fwollo.com'>info@fwollo.com</a>.</p>


        </div>
    )
}