import React, {useCallback} from "react";
import './AdvancedSearch.scss';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Undo from '../../../static/img/undo.svg';
import countryList from 'react-select-country-list';
import {LocationSearch} from "./LocationSearch/LocationSearch";
import {UserSearch} from "./UserSearch/UserSearch";
import {SearchForm} from "../SearchForm/SearchForm";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className={`advancedSearch-box__activeTab-${index}`}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AdvancedSearch = ({activeTab, locationData, onChangeTab, onSubmitVenues, onSubmitUser, onSubmitLocation, onSelectPhone, clearForm, clearHandle}) => {
    const countryObject = countryList().getData();

    const handleChange = useCallback((event, newValue) => {
        onChangeTab(newValue);
    }, [onChangeTab]);

    const onSelectLocation = useCallback((name) => (value) => {
        onSubmitLocation({ [name]: value });
    }, [onSubmitLocation]);

    return (
        <div className='advancedSearch'>
            <h2 className='advancedSearch-title'>Advanced Search</h2>
            <p className='advancedSearch-text'>* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum sed
                quis arcu, aliquam enim diam pharetra, imperdiet. Arcu, nunc, facilisis sit placerat in eu nunc, at.</p>
            <div className='advancedSearch-box'>
                <AppBar position="static" color='default'>
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Location" {...a11yProps(0)} className={`advancedSearch-box__activeLabel-0`}/>
                        <Tab label="Venue" {...a11yProps(1)} className={`advancedSearch-box__activeLabel-1`}/>
                        <Tab label="User" {...a11yProps(2)} className={`advancedSearch-box__activeLabel-2`}/>
                    </Tabs>
                </AppBar>
                <TabPanel value={activeTab} index={0}>
                    <LocationSearch
                        onCitySelect={onSelectLocation("city")}
                        onStateSelect={onSelectLocation("state")}
                        setSelectedCountry={onSelectLocation('country')}
                        locationData={locationData}
                        clearForm={clearForm}
                        countryObject={countryObject}
                    />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <SearchForm
                        selectedCountry='US'
                        onSubmit={onSubmitVenues}
                        countryObject={countryObject}
                        clearForm={clearForm}
                    />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <UserSearch
                        setSelectedQrCode={onSubmitUser}
                        setSelectedPhone={onSelectPhone}
                        clearForm={clearForm}
                    />
                </TabPanel>
            </div>
            <button className='button-grey center' onClick={clearHandle} ><img src={Undo} alt={Undo}/><span>Reset Search</span></button>
        </div>
    )
}