import React from "react";
import './ForMerchants.scss'
import {MerchantsContent} from "./Content/MerchantsContent";
import {SubscribeForm} from "../../components/SubscribeForm/SubscribeForm";
import Hero from "../../../static/img/forMerchants/Hero2.png";

export const ForMerchants = () => {
    return (
        <>
            <div className='merchantHero'>
                <div className="merchantHero-container">
                    <div className="merchantHero-container__image">
                        <img src={Hero} alt="Hero"/>
                    </div>
                    <div className="merchantHero-container__content">
                        <h1 className="merchantHero-container__title">
                            TruLink for business provides web dashboard for your venue
                        </h1>
                        <p className="merchantHero-container__text">
                            One scan of a QR code keeps you in touch with an ever-growing number of patrons and
                            followers.
                        </p>
                        <p className="merchantHero-container__text">
                            Insight, announcements, promotions; encouraging future interactions is easier than ever with
                            the
                            powerful Trulink platform by your side.
                        </p>
                    </div>
                </div>
            </div>
            <MerchantsContent/>
            <div className='tryIt'>
                <h2 className='tryIt-title'>Let's try it now!</h2>
                <p className='tryIt-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nunc orci
                    consectetur eget egestas nam aenean elit mauris. Proin convallis augue vel, fringilla consectetur
                    quam rhoncus erat amet.</p>
                <a className='button-blue center' href='/search'><span>Generate QR-code for a Venue</span></a>
            </div>
            <SubscribeForm label='For merchants'/>
        </>
    )
}
