import React from "react";
import '../HomeMainContent.scss'
import Privacy from "../../../../static/img/whyOver/privacy.svg";
import Track from "../../../../static/img/whyOver/track.svg";
import Offline from "../../../../static/img/whyOver/offline.svg";
import Notifi from "../../../../static/img/whyOver/notifi.svg";
import Clearing from "../../../../static/img/whyOver/clearing.svg";
import Confirmation from "../../../../static/img/whyOver/confirmation.svg";

export const WhyOver = () => {
    return (
        <div className='whyOver'>
            <h1 className='whyOver-headLine'>Why Trulink over other contact tracing platforms</h1>
            <div  className='whyOver-blocks'>
                <div className='whyOver-blocks__item'>
                    <img className='whyOver-blocks__item-img' src={Privacy} alt='Privacy'/>
                    <h4>Privacy</h4>
                    <p>Your personal data (name, ph, email, address) is not revealed to merchants, venues, other users.</p>
                </div>
                <div className='whyOver-blocks__item'>
                    <img className='whyOver-blocks__item-img' src={Track} alt="Doesn't Track You 24/7"/>
                    <h4>Doesn't Track You 24/7</h4>
                    <p>Every scan/check-in is initiated/controlled by user</p>
                </div>
                <div className='whyOver-blocks__item'>
                    <img className='whyOver-blocks__item-img' src={Offline} alt='Works Offline'/>
                    <h4>Works Offline</h4>
                    <p>Without bluetooth, without constant location tracking, even works for non-smartphone users</p>
                </div>
                <div className='whyOver-blocks__item'>
                    <img className='whyOver-blocks__item-img' src={Notifi} alt='Safe Notifications'/>
                    <h4>Safe Notifications</h4>
                    <p>All Alerts, Notifications, Messages from merchants are relayed through the Trulink platform thus protecting your personal contact details.</p>
                </div>
                <div className='whyOver-blocks__item'>
                    <img className='whyOver-blocks__item-img' src={Clearing} alt='Clearing History'/>
                    <h4>Clearing History</h4>
                    <p>By default all history past 30 days is deleted unless the user opts in to preserve the history.</p>
                </div>
                <div className='whyOver-blocks__item'>
                    <img className='whyOver-blocks__item-img' src={Confirmation} alt='2-Way Confirmation'/>
                    <h4>2-Way Confirmation</h4>
                    <p>Allows option for 2 way confirmation of scans for privacy and security</p>
                </div>
            </div>
        </div>
    )
}