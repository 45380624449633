import React, {useCallback} from "react";
import {isEmpty} from "lodash";
import UserImg from "../../../../../static/img/user.png";
import LocationImg from "../../../../../static/img/location.png";
import {Skeleton} from "@material-ui/lab";
import MailBlue from "../../../../../static/img/mailBlue.png";

export const ItemDetails = ({user, location, venue, getFlagDirectMessage}) => {

    const renderImage = useCallback(() => {
         if (!isEmpty(user)) {
             return (
                 <div className='info__itemDetails-img'>
                     <img src={UserImg} alt={UserImg} />
                 </div>
             )
         }
         if (!isEmpty(location)) {
             return (
                 <div className='info__itemDetails-img'>
                     <img src={LocationImg} alt={LocationImg} />
                 </div>
             )
         }
         if (!isEmpty(venue)) {
             return (
                 <div className='info__itemDetails-img'>
                     <Skeleton animation='wave' variant="circle" width={70} height={70}/>
                 </div>
             )
         }
         return null;
    }, [location, user, venue]);

    const renderSubTitle = useCallback(() => {
        if (!isEmpty(location)) {
            return (
                <h5 className='info__itemDetails-location'>{location.country &&location.country.replace( /_/g, ' ')}{location.state && `, ${location.state}`}{location.city && `, ${location.city}`}</h5>
            )
        }
        if (!isEmpty(venue)) {
            return (
                <h5 className='info__itemDetails-location'>{venue.location.country && venue.location.country}{venue.location.city && `, ${venue.location.city}`}{venue.location.address && `, ${venue.location.address}`}</h5>
            )
        }
       if (!isEmpty(user)) {
            return (
                <button className='info__itemDetails-directMessage' onClick={getFlagDirectMessage}><img src={MailBlue} alt={MailBlue} /><span>Send Message to the User</span></button>
            )
       }
        return null;
    }, [location, venue, user, getFlagDirectMessage]);

    return (
        <div className='info__itemDetails'>
            {renderImage()}
            <div className='info__itemDetails-info'>
                <h3 className='info__itemDetails-name'>
                    {venue && venue.name}
                    {location.state && location.state}
                    {user && (user.qrCode || user.phone)}
                </h3>
                {renderSubTitle()}
            </div>
        </div>
    )
}
