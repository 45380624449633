import React, {useCallback, useEffect, useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {Skeleton} from '@material-ui/lab';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import './ModalQR.scss';
import TextField from "@material-ui/core/TextField";
import QrImage from "../../../static/img/QR.png";
import moment from 'moment';
import GenerateQRService from "../../../services/GenerateQRService";
import {isEmpty} from "lodash";
import {CircularProgress} from '@material-ui/core';

export const ModalQR = ({open, venue, onClose, onChangeQr}) => {
    const [dialogOpen, setDialogOpen] = useState({open})
    const [qrCodeData, setQrCodeData] = useState({});
    const [expiration, setExpiration] = useState(false);
    const [location, setLocation] = useState(false);
    const [offline, setOffline] = useState(false)
    const [confirm, setConfirm] = useState(false);
    const [timeStamp, setTimeStamp] = useState(moment().valueOf());
    const [urlOptional, setUrlOptional] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setQrCodeData(prevState => {
            return {
                ...prevState,
                extId: venue.id,
                name: venue.name,
                redirectUrl: isEmpty(urlOptional) ? 0 : urlOptional,
                verificationParams: {
                    expirationTime: expiration ? timeStamp : Number(expiration),
                    GPS: Number(location),
                    offline: Number(offline),
                    confirm: Number(confirm),
                },

            }
        })
        if (!open) {
            setQrCodeData({});
            setExpiration(false);
            setLocation(false);
            setOffline(false);
            setConfirm(false);
            setUrlOptional('');
        }
    }, [confirm, expiration, location, offline, open, timeStamp, urlOptional, venue]);

    const handleChangeDate = useCallback((e) => {
        const {value} = e.target;
        const time = moment(value).valueOf();
        setTimeStamp(time);
    }, []);


    const handleChangeExpiration = useCallback(() => {
        setExpiration(!expiration);
    }, [expiration]);

    const handleChangeLocation = useCallback(() => {
        setLocation(!location);
    }, [location]);

    const handleChangeOffline = useCallback(() => {
        setOffline(!offline);
    }, [offline]);

    const handleChangeConfirm = useCallback(() => {
        setConfirm(!confirm);
    }, [confirm]);

    const handleChangeUrl = useCallback((e) => {
        const {value} = e.target;
        setUrlOptional(value)
    }, []);


    const dialogWindowHandler = useCallback(() => {
        setDialogOpen(prevState => !prevState);
    }, []);

    const handleForm = useCallback(async () => {
        try {
            setLoading(true);

            const response = await GenerateQRService.postRequest(qrCodeData);
            if (response) {
                onChangeQr(response);
                setLoading(false);
            }
        } catch (e) {
            console.log('handleForm', e.message)
            setLoading(false);
        }
    }, [onChangeQr, qrCodeData]);

    return (
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title"
                className='modalQr' open={open} scroll={"paper"}>
            <DialogTitle id="customized-dialog-title" onClose={dialogWindowHandler}>

                {dialogOpen ? (
                    <IconButton aria-label="close" className='searchResult-windowTitle__close'
                                onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <div>
                    <p className='modalQr-title'>Generate QR Code</p>
                    <p className='modalQr-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum sed
                        quis
                        arcu, aliquam enim diam pharetra, imperdiet. Arcu, nunc, facilisis sit placerat in eu nunc,
                        at.</p>
                    <div className='modalQr-venueInfo'>
                        <div className='modalQr-venueImg'>
                            <Skeleton variant="circle" width={70} height={70}/>
                        </div>
                        <div className='modalQr-venueInfoDetails'>
                            <h3 className='modalQr-venueName'>{venue.name}</h3>
                            <h5 className='modalQr-venueLocation'>{venue.location.country && venue.location.country}{venue.location.city && `, ${venue.location.city}`}{venue.location.address && `, ${venue.location.address}`}</h5>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p className='modalQr-textOption'>QR code options</p>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={expiration}
                                    onChange={handleChangeExpiration}
                                    name="checkedB"
                                    color="primary"
                                    size={"small"}
                                />
                            }
                            label="Expiration date/time"
                            className='modalQr-checkbox'
                        />
                        {!expiration ? null : (
                            <TextField
                                style={{margin: '15px 0'}}
                                id="date"
                                label="Expiration date/time"
                                type="date"
                                variant='outlined'
                                fullWidth
                                defaultValue="2020-08-05"
                                onChange={handleChangeDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={location}
                                    onChange={handleChangeLocation}
                                    name="checkedB"
                                    color="primary"
                                    size={"small"}
                                />
                            }
                            label="Verify location"
                            className='modalQr-checkbox'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={offline}
                                    onChange={handleChangeOffline}
                                    name="checkedB"
                                    color="primary"
                                    size={"small"}
                                />
                            }
                            label="Allow offline scan"
                            className='modalQr-checkbox'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={confirm}
                                    onChange={handleChangeConfirm}
                                    name="checkedB"
                                    color="primary"
                                    size={"small"}
                                />
                            }
                            label="Require confirmation by the other party"
                            className='modalQr-checkbox'
                        />

                        <p className='modalQr-textOptionLink'>Add a link to redirect after check-in (optional)</p>
                        <TextField
                            value={urlOptional}
                            onChange={handleChangeUrl}
                            label='URL'
                            variant='outlined'
                        />

                        <button className='modalQr-generateQrButton button-blue center' disabled={loading}
                                onClick={handleForm}>
                            {loading ? <CircularProgress size={16} style={{marginRight: '10px'}}/> : <img
                                src={QrImage}
                                alt={QrImage}/>}<span>Generate QR-Code</span>
                        </button>
                        <span
                            className='modalQr-generateQrInfo center'>* we will generate QR-code in PDF format</span>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}