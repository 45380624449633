import React from "react";
import './Privacy.scss'
import Helmet from "react-helmet";

export const Privacy = () => {
    return (
        <div className='privacy'>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Privacy Policy - Trulink</title>
            </Helmet>
            <h1 className='privacy-title'>Privacy Policy</h1>
            <h3 className='privacy-subTitle'>Last Updated June 16, 2020</h3>

            <p className='privacy-attention'>PLEASE READ THROUGH THE PRIVACY POLICY CAREFULLY AS THEY CONSTITUTE A
                BINDING LEGAL AGREEMENT BETWEEN YOU AND FWOLLO LLC. IF YOU ARE A CALIFORNIA RESIDENT, YOU ENJOY
                ADDITIONAL RIGHTS AND BENEFITS. SEE SECTIONS IX AND X.</p>

            <p>
                Dear user,<br/>
                Welcome to TruLink!
            </p>

            <p>TruLink provides discretionary opt-in services via our mobile application and related websites
                (collectively, the <strong>“Platform”</strong>) that aid and enable users
                (<strong>“you”</strong>, <strong>“your”</strong>, <strong>“user”</strong> or <strong>“users”</strong>)
                in
                tracking locations where they have been and people they have come into contact with (the
                <strong>“Services”</strong>).</p>

            <p>Fwollo LLC, its parents, subsidiaries and affiliates, as applicable
                (collectively, <strong>“we”</strong>, <strong>“our”</strong>, <strong>“us”</strong>,
                <strong>“ourselves”</strong> or <strong>“TruLink”</strong> together with you,
                the <strong>“Parties”</strong> and each, individually, a <strong>“Party”</strong>), appreciate
                your business, visit to, and use of our Platform and Services.</p>

            <p>As a visitor to our Platform and user of our Services, we consider the privacy of your content to be of
                paramount importance and at the top of our priorities. Information we gather from you as a user, not
                only
                facilitates interactions and transactions between us and you and other users, but also helps us
                continually
                improve your Platform and Services experience.</p>

            <p>Towards assuring you of the security and protection of the content you provide, relay and/or generate
                while
                using our Platform and Services, we outline below our policy that governs the use of the Platform, and
                Services to protect your personal data as informed by the requirements of law (the <strong>“Privacy
                    Policy”</strong>). Upon
                access to our Platform and use of the Services, you are bound by and subject to all the terms
                herein. <strong>Please
                    read them carefully</strong>. This Privacy Policy is effective immediately and is incorporated by
                reference into, and
                a part of our Terms of Use, which governs your use of our Platform in general. This Privacy Policy may
                be
                edited, amended, modified, revised, added to, or reduced, from time to time, at our sole discretion. You
                shall be bound by any and all amendments, modifications or revisions to the Privacy Policy. Please note
                that
                we are under no obligation to notify you of any such changes to the Privacy Policy. You should therefore
                visit this page periodically to review the Privacy Policy. Each time you access the Platform and engage
                in
                the use of the Services, you are agreeing to be legally bound by the most updated Privacy Policy
                provided.
            </p>
            <p>In providing the Services, we may process your information outside the United States. In such
                jurisdictions
                in which we have a presence and process your information, different laws surrounding privacy may apply
                with
                varying degrees of protection afforded by applicable law. Notwithstanding this fact and subject to any
                country-specific or other terms herein to the contrary, we shall, at a bare minimum act in accordance
                with
                the protection levels provided in this Privacy Policy when processing your information in any
                jurisdiction
                outside the United States.</p>


            <p>All capitalized terms not otherwise defined in this Privacy Policy shall have the same definitions
                ascribed
                to them in the Terms of Use.</p>

            <p><strong>I. Data Collection</strong></p>

            <p>In order to facilitate your effective use of the Platform and the Services provided by TruLink, we may
                request, require, collect and obtain certain information from you ranging from primary person
                information to
                other preferential information.</p>

            <p>Primary personal information we may request, require, collect and obtain from you include, without
                limitation:</p>

            <p>(i) your name;<br/>
                (ii) your email address;<br/>
                (iii) your phone number and fax number (where applicable);<br/>
                (iv) your residential address;<br/>
                (v) your photo or avatar;<br/>
                (vi) your debit, credit card, bank account or other payment or billing information;<br/>
                (vii) your current location at any given time;<br/>
                (vii) locations that you visit/frequent;</p>

            <p>Other information we may request, require, collect and obtain from you, include, without limitation:</p>

            <p>(i) additional personal information for further authentication of your identity and/or for background
                check;<br/>
                (ii) your personal, business or other interests and preferences;<br/>
                (iii) your opinion or experience through a survey or questionnaire based on your use of the Platform and
                the
                Services;<br/>
                (iv) information on your interactions with other users of the Platform and Services or other related
                third
                parties; and<br/>
                (iv) your usage data.</p>

            <p><strong>II. Usage Data</strong></p>

            <p>Usage data or activity data is collected automatically when using the Platform and Services. This may
                include information such as your device’s internet protocol address (also known as, the IP address),
                browser
                data, and diagnostic data on what pages you visit within the Platform and upon using the Services, how
                long
                you spend on those pages, and what times you access them.</p>

            <p><strong>III. Cookies</strong></p>

            <p>Cookies are small data identifiers that our Platform system places in your computing device that allow
                our
                Platform to recognize users and their preferences when they visit and use the Platform and the Services
                in
                order to improve their user experience. Cookies are therefore important components and useful tools to
                the
                operation and use of our Platform and Services. The Platform thus utilizes cookies that track your
                activities related to our Services. Please note that your browser can be instructed to block all
                cookies.
                However, blocking cookies may restrain you from using certain aspects of our Services and may compromise
                your user experience on the Platform.</p>

            <p><strong>IV. Personal Data Use</strong></p>

            <p>We may use your personal information for reasons, including, but not limited to: (i) providing or
                maintaining our Platform and Services; (ii) monitoring and analyzing our Platform performance; (iii)
                running
                diagnostic tests; (iv) managing accounts and registration through our Services; (v) fulfilling
                contractual
                obligations such as any agreed payments; (vi) making contact with you for notifications of security
                information, updates of terms, and other pertinent information; (vii) providing you with news, special
                offers, and other information related to our Services; and (viii) managing your requests and
                communications.</p>

            <p>We may share your information with third parties for reasons, including, but not limited to: (i)
                monitoring
                and analyzing Platform and Services use; (ii) contact you in connection with business transfers,
                mergers,
                acquisitions, or any other business deals that require a transfer of information; (iii) the extent
                necessary
                for our parents, subsidiaries, and affiliates to acquire and maintain such knowledge and information for
                which they will be bound under the same Privacy Policy; and (iv) connect with other users of the
                Services in
                a public space.</p>

            <p><strong>V. Retention of Personal Data</strong></p>

            <p>We will retain your personal information to the extent necessary in accordance with the terms in this
                Privacy Policy or to uphold any legal obligation that we may have. We will retain this information only
                for
                the duration necessary in accordance with the terms in this Privacy Policy and to uphold any legal
                obligations we may have.</p>

            <p>Your usage data will be stored for analytics and diagnostics in order to strengthen security and improve
                functionality of the Platform and Services.</p>

            <p><strong>VI. Transferring Data</strong></p>

            <p>You hereby agreed that your information may be transferred to and maintained outside of the jurisdiction
                of
                the state, country, or province in which you reside (as explained above), to any processing center,
                operating center, or other facility that we may utilize in connection with our Platform and
                Services.</p>

            <p>We will take reasonable steps to ensure that this Privacy Policy is upheld through any of these
                information
                transfers.</p>

            <p><strong>VII. Disclosing Data</strong></p>

            <p>We may disclose your information and/or data to the extent necessary for any business transaction
                including,
                without limitation, transfer of management, mergers, acquisitions, and asset sales at our sole
                discretion.</p>

            <p>TruLink is entitled to disclose your information if required or compelled to do so by applicable law
                or in
                response to any valid and legally substantiated requests from law enforcement agencies in the course
                of
                investigations, procedures, litigations, etc.</p>

            <p>We may, at our sole discretion disclose your personal information or data based on a good faith
                belief that
                it is necessary to comply with a legal obligation, protect our property or rights, protect the
                safety of any
                individual or the public at large, or shield, guard, or protect ourselves from liability.</p>

            <p><strong>VIII. Data Security</strong></p>

            <p>The security of your personal information and data is important to us. We strive to use all
                reasonable and
                commercially acceptable means of protecting and securing your data. However, no internet
                transmission is
                completely and fully secure. We therefore do not guarantee your information’s absolute
                security.</p>

            <p><strong>IX. California Residents</strong></p>

            <p><strong>A. Privacy Rights</strong></p>

            <p>California residents have comprehensive privacy rights enshrined in (i) the California
                Constitution, (ii) the California Online Privacy Protection Act (CalOPPA), (iii) the Privacy Rights for
                California Minors in the Digital World Act, (iv) the Shine the Light Law, and (v) the California
                Consumer Privacy Act of 2018 (CCPA).</p>

            <p>The California Constitution establishes the overarching inalienable rights of the state’s residents,
                which includes the right to privacy. CalOPPA was the first law in the United States requiring online
                business establishments to provide a privacy policy.</p>

            <p>The privacy rights in the other California laws are explained under the headings below.</p>

            <p><strong>B. The Shine the Light Law</strong></p>

            <p>Under the Shine the Light Law, Cal. Civ. Code § 1798, California residents have the right to request
            information once a year regarding the sharing of their personal data with third parties for direct marketing
            purposes or to opt-out of information sharing practices of the business in question holding their personal
            information.</p>

            <p>Where a business holding California resident-user information has no opt-out options for users or does not
            meet the threshold for a business that the law applies to, the law endows residents with the right to: (i)
            know details of the information the business provided to third parties for direct marketing purposes, and
            (ii) the name and list of all third parties that where in receipt of the user’s personal information.</p>

                <p>We may be subject to the Shine the Light Law. Where it is deemed that we qualify to be subject to the Shine
            the Light Law, and in the event that we are, we shall endeavor to provide you with the relevant information
            on the use of your personal information for third-party direct marketing purposes, in accordance with the
            law.</p>

            <p>More information is available at these resources:{'\u00A0'}
                <a
                    className='link'
                    href='https://www.gibsondunn.com/wp-content/uploads/documents/publications/Biancamano-ShiningtheLight.pdf'
                    target='_blank'
                    rel='noopener noreferrer'>
                     Cal. Civ. Code § 1798/Shining the Light on California
                </a>. If
            you would still like to know more about your rights as a California resident, or if you would like to
            request information about our use of your personal information in accordance with the Shine the Light Law,
            you can contact us using the contact details provided below.</p>

            <p><strong>C. Privacy Rights for California Minors in the Digital World Act</strong></p>

            <p>Cal. Bus. and Prof. Code § 22581 gives any minor, being a person under the age of 18, residing in California
            the right to request and obtain the removal of any information that they have made available through a
            public site. However, in the event that this applies to TruLink, please be aware that removal of public
            information by us does not guarantee that the information has not already been made or will be made
                available elsewhere.</p>

            <p>For the avoidance of doubt, and as provided in our Terms of Use, our Platform and Services are not to be
            used by persons under 18 years of age. You hereby represent that you are over the age of 18.</p>

            <p>To request the removal of your information contact us using the contact details provided below.</p>

            <p><strong>D. California Consumer Privacy Act</strong></p>

            <p>The CCPA is the most recent Californian law on privacy to go into effect. It went into effect on January 1,
            2020. This law applies to any business that (i) sells the personal information of 50,000 California
            residents annually, or (ii) that has an annual gross revenue exceeding $25 million, or (iii) derives more
                than 50% of its annual revenue from sales of the personal information of California residents.</p>

                <p>Under the CCPA, California residents have the right to: (i) receive notice of a company’s practices
            surrounding the collection, use, sale and sharing of their personal information, (ii) know what personal
            information has been collected about the resident and the right to receive a copy thereof, (iii) deletion
            upon request, of personal information that a company has collected and retained on a California resident,
            (iv) opt-out of the collection of a California resident’s personal information by a company that sells such
            information, (v) opt-in for minors, if a company has active knowledge that the California resident is a
            minor or willfully disregards age, and (vi) non-discrimination of California residents that have exercised
                    their rights under the CCPA.</p>

                    <p>We may be subject to the CCPA. Where it is deemed that we qualify to be subject to the CCPA, and in the
            event that we are, we shall endeavor to provide you with the relevant information on the use of your
                        personal information, in accordance with the law.</p>

                        <p>More information is available at these resources: {'\u00A0'}
                            <a
                                className='link'
                                href='https://oag.ca.gov/privacy/ccpa'
                                target='_blank'
                                rel='noopener noreferrer'>
                                https://oag.ca.gov/privacy/ccpa
                            </a>
                            . If you would still like
            to know more about your rights as a California resident, or if you would like to request information about
            our use of your personal information in accordance with the CCPA, you can contact us using the contact
                            details provided below.</p>


            <p><strong>X. Right to a Copy</strong></p>
            <p>You have a legal right to request and obtain a copy of the content we hold about you. This includes content
            on how this will be used and to ensure this content is accurate. You may review and edit the personal
            information that is stored in your Account on our Platform. You may send us new or updated content at any
            time. Taking into account the foregoing, we will endeavor to respond to your request to access, update or
            delete your content as soon as practicable. Before we are able to provide you with any content, correct any
            inaccuracies or delete any content, however, we may ask you to verify your identity and to provide other
            details to help us to respond to your request. In addition, you have the right to request access to the
            personal information that we hold about you at any time.</p>

            <p><strong>XI. Contact Us</strong></p>

                <p>In case of any questions, comments, suggestions, requests or other inquiries about this Privacy Policy, our
                    Platform and Services, please feel free to reach out to us via e-mail at <a className='link' href='mailto:info@fwollo.com'>info@fwollo.com</a>.</p>
        </div>
    )
}