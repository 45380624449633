import React, {useCallback} from "react";
import '../ItemInformation.scss';
import Mail from "../../../../../static/img/mail.png";
import {isEmpty} from "lodash";
import {SendMessage} from "../../../../components/SendMessage/SendMessage";

export const UserSendMessage = ({
                                    tab,
                                    user,
                                    open,
                                    venue,
                                    nowDate,
                                    lastWeek,
                                    location,
                                    activeTab,
                                    statistic,
                                    lastMonth,
                                    customDate,
                                    flagDirect,
                                    handleClickOpen
                                }) => {

    const renderUsersCount = useCallback(() => {
        if (activeTab === 0) {
            if (tab === 'All time') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.location.allTime.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Last Week') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.location.lastWeek.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Last Month') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.location.lastMonth.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Custom') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.location.custom.users}</strong> users in this place
                    </h4>
                );
            }
        }
        if (activeTab === 1) {
            if (tab === 'All time') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.venue.allTime.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Last Week') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.venue.lastWeek.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Last Month') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.venue.lastMonth.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Custom') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.venue.custom.users}</strong> users in this place
                    </h4>
                );
            }
        }
        if (activeTab === 2) {
            if (tab === 'All time') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.user.allTime.contacts}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Last Week') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.user.lastWeek.contacts}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Last Month') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.user.lastMonth.contacts}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Custom') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.user.custom.contacts}</strong> users in this place
                    </h4>
                );
            }
        }
        if (activeTab === 0 && isEmpty(location)) {
            if (tab === 'All time') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.location.allTime.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Last Week') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.location.lastWeek.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Last Month') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.location.lastMonth.users}</strong> users in this place
                    </h4>
                );
            }
            if (tab === 'Custom') {
                return (
                    <h4 className='info__statistics-messages-title'>You can send a message
                        to <strong>{statistic.venue && statistic.location.custom.users}</strong> users in this place
                    </h4>
                );
            }
        }
    }, [activeTab, location, statistic, tab]);

    return (
        <div className='info__statistics-messages'>
            <div className='info__statistics-messagesText'>
                {renderUsersCount()}
                <p className='info__statistics-messages-text'>Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Rutrum sed quis arcu, aliquam enim diam pharetra, imperdiet. Arcu, nunc, facilisis sit
                    placerat in eu nunc, at.</p>
            </div>
            <div className='info__statistics-messagesAction'>
                <button className='modalQr-generateQrButton button-blue' onClick={handleClickOpen}><img src={Mail}
                                                                              alt={Mail}/><span>Send Messages</span>
                </button>
            </div>
            <SendMessage
                tab={tab}
                open={open}
                user={user}
                venue={venue}
                nowDate={nowDate}
                lastWeek={lastWeek}
                location={location}
                activeTab={activeTab}
                statistic={statistic}
                lastMonth={lastMonth}
                customDate={customDate}
                flagDirect={flagDirect}
                handleClickOpen={handleClickOpen}
            />
        </div>
    )
}