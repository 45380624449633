import React from "react";
import moment from "moment";
import MessageInformation from '../../../../static/img/messageInformation.png'
import {camelCase, startCase} from "lodash";

export const MessageListItem = ({item}) => {
    return (
        <div className='messageList-iBox-item'>
            <div className='messageList-iBox-item-name'>
                <div className='messageList-iBox-item-nameInfo'>
                    <img src={MessageInformation} alt={MessageInformation} />
                    <p>{item.notificationData.title}</p>
                </div>
                <h5>{item.notificationData.message}</h5>
            </div>
            <div className='messageList-iBox-item-data' style={{width: '80px'}}>
                {item.createdAt && moment(item.createdAt).format('D, MMM')}
            </div>
            <div className='messageList-iBox-item-' style={{width: '256px'}}>
                {(startCase(camelCase(item.type)))}
            </div>
            <div className='messageList-iBox-item-count' style={{width: '100px'}}>
                {item.received}
            </div>
            <div className='messageList-iBox-item-count' style={{width: '100px'}}>
                {item.read}
            </div>
        </div>
    )
}