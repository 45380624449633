import React from "react";
import View from "../../../../static/img/forMerchants/View.svg";
import Send from "../../../../static/img/forMerchants/Send.svg";
import Customize from "../../../../static/img/forMerchants/Customize.svg";

export const MerchantsContent = () => {
    return (
        <div className='merchantContent'>
            <h2 className='merchantContent-title'>Features TruLink for your Business</h2>
            <div className='merchantContent-container'>
                <div className='merchantContent-box'>
                    <div className='merchantContent-image'>
                        <img src={View} alt="View"/>
                    </div>
                    <div className='merchantContent-contentBox'>
                        <p className='merchantContent-subtitle'>1. View Advanced Statistic</p>
                        <ul className='merchantContent-list'>
                            <li className='merchantContent-listItem'>See Check-ins by time range</li>
                            <li className='merchantContent-listItem'>..........</li>
                        </ul>
                    </div>
                </div>
                <div className='merchantContent-box'>
                    <div className='merchantContent-image'>
                        <img src={Send} alt="Send"/>
                    </div>
                    <div className='merchantContent-contentBox'>
                        <p className='merchantContent-subtitle'>2. Send messages and receive notifications</p>
                        <ul className='merchantContent-list'>
                            <li className='merchantContent-listItem'>Send messages based on Custom Query or Rule</li>
                            <li className='merchantContent-listItem'>Get alerts based on policy</li>
                            <li className='merchantContent-listItem'>Messages delivered through the app, sms or email</li>
                        </ul>
                    </div>
                </div>
                <div className='merchantContent-box'>
                    <div className='merchantContent-image'>
                        <img src={Customize} alt="Customize"/>
                    </div>
                    <div className='merchantContent-contentBox'>
                        <p className='merchantContent-subtitle'>3. Customize QR-code</p>
                        <ul className='merchantContent-list'>
                            <li className='merchantContent-listItem'>Allow offline scan</li>
                            <li className='merchantContent-listItem'>Require location verification</li>
                            <li className='merchantContent-listItem'>Create expiring codes</li>
                            <li className='merchantContent-listItem'>Require 2-way confirmation</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}