import React from "react";
import './ContactForm.scss';

export const ContactForm = ({
                                onSubmit,
                                onChangeContact,
                                onChangeVenue,
                            }) => {

    return (
        <div className='contactForm'>
            <form onSubmit={onSubmit}>
                <input
                    type="email"
                    name='email'
                    onChange={onChangeContact}
                    placeholder='E-mail'
                    required
                />
                <input
                    type="text"
                    name='venueName'
                    onChange={onChangeVenue}
                    placeholder='Venue name'
                />
                <input
                    type="text"
                    name='name'
                    onChange={onChangeContact}
                    placeholder='Contact person'
                />
                <input
                    type="tel"
                    name='phone'
                    onChange={onChangeContact}
                    placeholder='Contact number'
                />
                <input
                    type="text"
                    name='address'
                    onChange={onChangeVenue}
                    placeholder='Address'
                />
                <button type="submit" className='button-blue'><span>SEND</span></button>
            </form>
        </div>
    )
}