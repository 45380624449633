import React from "react";

export const Navigation = ({menu, menuItem}) => {
    return (
        <nav className={menu}>
            <div className='side-drawer-line'/>
            <a className={menuItem} href='/for-merchants'>For business ></a>
            <a className={menuItem} href='/search'>Get QR-code for a Venue ></a>

            <div className='side-drawer-footer'>
                <ul className='side-drawer-footerMenu'>
                    <a className='side-drawer-footerMenu__item' href='/terms'>Terms of Use</a>
                    <a className='side-drawer-footerMenu__item' href='/privacy'>Privacy Policy</a>
                </ul>
                <p className='side-drawer-footerCopy'>© 2020 TruLink</p>
            </div>
        </nav>
    )
}