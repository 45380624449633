import React from "react";
import {Navigation} from "../Navigation";

function SideDrawer(props) {
    const {show} = props;
    let {menu} = props;
    if (show) {
        menu = 'side-drawer open'
    }

    return (
        <Navigation
            menu={menu}
            menuItem='side-drawer-item'
        />
    )
}

export default SideDrawer;