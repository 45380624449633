import React, {useCallback} from 'react';
import QRCode from "qrcode.react";
import {ReactComponent as Logo} from "../../../static/img/logoWhite.svg";
import Background from "../../../static/img/PDF/bg2x.png";
import {ReactComponent as AppStore} from "../../../static/img/AppStore.svg";
import {ReactComponent as GooglePlay} from "../../../static/img/GooglePlay.svg";


export const PDFTemplate = ({venue, pdfRef, qrURL}) => {

    const venueNameFontSizeStyle = useCallback(() => {
        if (venue.name.length > 13 && venue.name.length <35) {
            return '44px'
        } else if (venue.name.length > 35) {
            return '35px'
        }
        return '88px'
    }, [venue]);

    const venueNameLineHeightStyle = useCallback(() => {
        if (venue.name.length > 35) {
            return 'initial'
        }

        return '68px'
    }, [venue]);

    const checkInStyles = useCallback(() => {
        if (venue.name.length > 13) {
            return '0px'
        }
        return '30px'
    }, [venue])

    return (
        <div style={{
            width: 820,
            height: 1060,
            marginTop: '-40px',
            fontFamily: 'Gilroy',
            background: `#1890FF url(${Background})`,
            backgroundSize: 'cover',
        }} ref={pdfRef}>
            <div>
                <div>
                    <h3 style={{
                        color: 'white',
                        paddingTop: '50px',
                        fontSize: '41px',
                        lineHeight: '33px',
                        fontWeight: '500',
                        textAlign: 'center',
                        fontFamily: "inherit",
                        marginBottom: checkInStyles(),
                    }}>Check-in to</h3>
                    <h1 style={{
                        color: 'white',
                        textAlign: 'center',
                        fontSize: venueNameFontSizeStyle(),
                        marginTop: '10px',
                        lineHeight: venueNameLineHeightStyle(),
                        fontWeight: '900',
                        fontFamily: "inherit",
                        paddingBottom: '0',
                        marginBottom: '0',
                    }}>{venue.name}</h1>
                </div>
                <div style={{
                    margin: '20px auto',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}>
                    <AppStore style={{margin: '0 10px', width: '206px'}}/>
                    <GooglePlay style={{margin: '0 10px', width: '206px'}}/>
                </div>
                <div style={{
                    margin: '30px auto',
                    width: 594,
                    height: 594,
                    background: 'rgba(144, 199, 249, 0.5)',
                    boxShadow: '-30px 10px 20px rgba(10, 68, 121, 0.1)',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        height: '3px',
                        backgroundColor: 'white',
                    }
                }}>
                    <div style={{
                        width: 478,
                        height: 478,
                        background: '#FFFFFF',
                        boxShadow: '-30px 20px 30px rgba(5, 80, 149, 0.2)',
                        borderRadius: '50%',
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}>
                        <QRCode className='qrCode' size={275} style={{alignSelf: 'center'}}
                                value={qrURL ? qrURL.url : `https://trul.ink/2/${venue.id}/${venue.name.replace(/ /g, '_')}`}
                                renderAs='svg'/>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                    <p style={{
                        marginTop: '0',
                        color: 'white',
                        textAlign: 'center',
                        fontFamily: "inherit",
                        fontSize: '26px',
                        lineHeight: '30px',
                    }}>
                        * point your phone camera to this code to <br/> get a link to the app
                    </p>
                    <Logo style={{width: '176px'}}/>
                </div>
            </div>
        </div>
    )
}