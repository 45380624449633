import React from "react";

function Backdrop(props) {
    const {click} = props;

    return (
        <div className='backdrop' onClick={click} />
    )
}

export default Backdrop;