import React, {memo, useCallback, useEffect, useState} from "react";
import autocompleteService from "../../services/AutocompleteService";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {isEmpty} from "lodash";
import {makeStyles} from "@material-ui/core/styles";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';



const fetchPlaces = async (query, near) => {
    try {
        return await autocompleteService.postRequest({
            near,
            query,
        });
    } catch (e) {
        console.log(e.message, 'fetchPlaces');
    }
};
const useStyles = makeStyles((theme) => ({
    progress: {
        width: '30px',
        height: '30px',
        color: 'rgb(24, 144, 255)',
        marginTop: '30px'
    }
}))

const VenueAutocomplete = memo(({
                                    near,
                                    onVenueSelect,
                                    countrySelection,
                                    clearForm
                                }) => {
    const classes = useStyles();
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedVenue, setSelectedVenue] = useState(null);

    useEffect(() => {
        setSearchSuggestions([]);
        setInputValue('');
        onVenueSelect({});
        setSelectedVenue(null);
    }, [near, countrySelection, onVenueSelect, clearForm]);

    useEffect(() => {
        if (near && inputValue.length > 2 && inputValue.length < 100) {
            fetchPlaces(inputValue.replace( /  +/g, ' '), near)
                .then((response) => {
                    if (!response.type) {
                        setSearchSuggestions(response)
                    }
                    return null;
                })
        }
    }, [inputValue, near]);

    const onChange = useCallback((event, value) => {
        setSelectedVenue(value);
        onVenueSelect(value);
    }, [onVenueSelect]);

    const onInputChange = useCallback((event, value) => {
        setInputValue(value);
    }, []);


    const filterOptions = createFilterOptions({
        ignoreAccents: true,
        matchFrom: 'any',
        trim: true,
    });

    return (
        <>
            <Autocomplete
                id="combo-box-demo"
                disabled={!near}
                autoHighlight={true}
                onClose={() => setSearchSuggestions([])}
                style={{width: '100%'}}
                clearOnEscape
                freeSolo
                open={!isEmpty(searchSuggestions) && !selectedVenue}
                options={searchSuggestions}
                filterOptions={filterOptions}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                renderOption={(option) => {
                    return (
                        <Grid container alignItems='flex-start'>
                            <Grid item xs={12}>
                                <Typography>{option.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color='textSecondary'>
                                    {option.location.city}{(option.location.city && option.location.address) && `, `}{option.location?.address}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }}
                value={selectedVenue}
                inputValue={inputValue}
                onChange={onChange}
                onInputChange={onInputChange}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={'Places'}
                        placeholder='Start typing to get suggestions'
                        variant="outlined"
                    />
                }
            />
            {inputValue.trim() && isEmpty(searchSuggestions) ? <CircularProgress className={classes.progress}/> : null}
        </>
    )
})

export default VenueAutocomplete;
