import {USER_URLS} from "../constants/urls";
import CRUDService from "./CRUDService";

class LocationStatistics extends CRUDService {
    constructor() {
        super(USER_URLS.LOCATION_STATISTICS)
    }

}

export default new LocationStatistics();