import React from "react";
import {Jumbotron} from "../../components/Jumbotron/Jumbotron";
import {HomeMainContent} from "../../components/HomeMainContent/HomeMainContent";
import Helmet from "react-helmet";

export const Home = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Trulink</title>
            </Helmet>
            <Jumbotron/>
            <HomeMainContent/>
        </>
    )
}