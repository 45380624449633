import React, {useCallback, useEffect, useState, useMemo} from "react";
import './Statistic.scss'
import StatisticsService from "../../../services/StatisticsService";
import {isEmpty} from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useMediaQuery} from "@material-ui/core";

export const Statistics = ({id}) => {
    const width425 = useMediaQuery('(max-width:425px)');
    const [venueStats, setVenueStats] = useState({})
    const timeZone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, [])

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                const result = await StatisticsService.postRequest({extId: id, timeZone});
                setVenueStats(result)
            } catch (e) {
                console.log(e.message, 'fetchStatistics')
            }
        }

        fetchStatistics();
    }, [id, timeZone]);

    const renderStats = useCallback(() => {
        if (isEmpty(venueStats)) {
            return <CircularProgress style={{color: '#1890FF'}}/>
        } else if (venueStats.success === false) {
            return <p className='statistic-noItems'>We don't have statistics about this place...</p>
        }
        return (
            <table className='statistic-table'>
                <tbody>
                <tr>
                    <td>
                        <span className='statistic-table__period'>30 days</span></td>
                    <td>
                        {width425 ? <span className='statistic-table__value'>{venueStats.statistics.checkInsInThirtyDays}</span> :
                            <span className='statistic-table__period'>24 hrs</span>}
                    </td>
                </tr>
                <tr>
                    <td>
                        {width425 ? <span className='statistic-table__period'>24 hrs</span> :
                            <span className='statistic-table__value'>{venueStats.statistics.checkInsInThirtyDays}</span>}
                    </td>
                    <td><span className='statistic-table__value'>{venueStats.statistics.checkInsTwentyFourHours}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }, [width425, venueStats]);

    return (
        <div className='statistic'>
            <p className='statistic-title'>Check-in Statistics:</p>
            {renderStats()}
        </div>
    )
}