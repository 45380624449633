import React, {useCallback, useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import '../AdvancedSearch.scss';
import states from 'states-us';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import countries from 'countries-list';

export const LocationSearch = ({locationData, onStateSelect, onCitySelect, clearForm, setSelectedCountry}) => {
    const countryObj = countries.countries;
    const [city, setCity] = useState('');
    const [stateSelect, setStateSelect] = useState('');
    const [citySelect, setCitySelect] = useState(false);

    const renderCountries = useCallback(() => {
        if (countryObj) {
                return Object.values(countryObj).map(country => {
                    return (
                        <option key={country.name} value={country.native}>{country.name}</option>
                    )
                })
        }
        return null
    }, [countryObj])

    useEffect(() => {
        setCitySelect(false);
        setStateSelect('');
    }, [clearForm]);

    const onSelectState = useCallback((event) => {
        const {value} = event.target;
        setStateSelect(value);
        onStateSelect(value);
        setCity('');
    }, [onStateSelect]);

    const cityHandler = useCallback((event) => {
        const {value} = event.target;
        setCity(value);
    }, []);

    const onSelectCity = useCallback((value) => {
        onCitySelect(value);
    }, [onCitySelect]);

    const citySelectHandler = useCallback(() => {
        setCitySelect(!citySelect);
    }, [citySelect]);

    const countrySelectHandler = useCallback((event) => {
        const {value} = event.target;
        setSelectedCountry(value && value.replace(/ /g, '_'));
    }, [setSelectedCountry]);

    const renderStates = useCallback(() => {
        if (states) {
            return states.map(state => {
                return (
                    <option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
                )
            })
        }
        return null;
    }, []);

    return (
        <div className='advancedSearch__location'>
            <div>
                <FormControl fullWidth variant='outlined'>
                    <InputLabel id='labelCountry'>Country</InputLabel>
                    <Select
                        labelId='labelCountry'
                        label='Country'
                        native
                        onChange={countrySelectHandler}
                    >
                        <option value=''></option>
                        {renderCountries()}
                    </Select>
                </FormControl>
            </div>
            {locationData.country && locationData.country === 'United_States' ?
                <div style={{marginTop: '27px'}}>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='labelState'>State</InputLabel>
                        <Select
                            labelId='labelState'
                            label='State'
                            native
                            onChange={onSelectState}
                            value={stateSelect}
                        >
                            <option value=''></option>
                            {renderStates()}
                        </Select>
                    </FormControl>
                </div>
                : null}
            {!citySelect ? null :
                <div style={{marginTop: '27px'}}>
                    <TextField
                        variant={"outlined"}
                        fullWidth
                        value={city}
                        label='City'
                        onChange={cityHandler}
                        onKeyPress={(e,) => {
                            const {value} = e.target;
                            if (e.key === 'Enter') {
                                onSelectCity(value);
                            }
                        }}
                    />
                </div>
            }
            <button onClick={citySelectHandler} className='advancedSearch__location-addCity'>
                {!citySelect ? '+ Add Сity selection' : '- Remove Сity selection'}</button>
        </div>
    )
}