import React, {useCallback, useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from '@material-ui/lab';
import UsersAutocomplete from "../../../../services/UsersAutocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {isEmpty} from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiPhoneInput from 'material-ui-phone-number';

const fetchUsers = async (qrCode) => {
    const data = {
        qrCode
    }
 try {
     return await UsersAutocomplete.getRequest(data)
 } catch (e) {
     console.log(e.message, 'fetchUsers');
 }
};

export const UserSearch = ({setSelectedQrCode, setSelectedPhone, clearForm}) => {

    const [option, setOption] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [phone, setPhoneNumber] = useState('');

    useEffect(() => {
        if (selectedUser === null) {
            setOption([]);
        }
    },[selectedUser]);

    useEffect(() => {
        if (clearForm) {
            setPhoneNumber('');
            setInputValue('');
            setSelectedUser(null);
            setOption([]);
        }
    }, [clearForm, setSelectedPhone]);

    useEffect(() => {
        if (inputValue.length > 3 && inputValue.length < 20) {
            fetchUsers(inputValue.toUpperCase())
                .then((response) => {
                        setOption(response.users)
                })
        }
    }, [inputValue]);


    const onChange = useCallback((event, value) => {
        setOption(value);
        setSelectedUser(value);
        setSelectedQrCode(value);
    }, [setSelectedQrCode]);

    const onChangePhone = useCallback( (value) => {
        setPhoneNumber(value);
    }, []);

    const onInputChange = useCallback((event, value) => {
        setInputValue(value);
    }, []);

    return (
        <div className='advancedSearch__user'>
            <Autocomplete
                onClose={() => setOption([])}
                clearOnEscape
                options={option}
                onChange={onChange}
                value={selectedUser}
                inputValue={inputValue}
                disabled={!isEmpty(phone)}
                onInputChange={onInputChange}
                getOptionLabel={option => option.qrCode}
                getOptionSelected={option => option.qrCode}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        type='text'
                        label='User ID'
                        value={inputValue}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {inputValue && isEmpty(option) ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
                renderOption={(option) => {
                    return (
                        <Grid container alignItems='flex-start'>
                            <Grid item xs={12}>
                                <Typography>{option.qrCode}</Typography>
                            </Grid>
                        </Grid>
                    )
                }}
            />
            <span className='advancedSearch__user-or'>or</span>
            <MuiPhoneInput
                label='Phone'
                value={phone}
                disabled={!isEmpty(selectedUser)}
                defaultCountry={'us'}
                onChange={onChangePhone}
                variant={'outlined'}
                disableAreaCodes={true}
                onKeyPress={(e, ) => {
                    const {value} = e.target;
                    if (e.key === 'Enter') {
                        setSelectedPhone(value);
                    }
                }}
            />
        </div>
    )
}