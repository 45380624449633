import React from "react";
import './Footer.scss'
import Logo from "../../../static/img/logoBlue.svg";

export const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-logo'>
                <a href='/'>
                    <img src={Logo} alt="logo"/>
                </a>
            </div>
            <div className='footer-nav'>
                <a className='footer-nav__link' href='/terms'>Terms of Use</a>
                <a className='footer-nav__link' href='/privacy'>Privacy Policy</a>
                <span className='footer-nav__copy'>© 2020 TruLink</span>
            </div>
        </div>
    )
}