import React, {useCallback, useState} from "react";
import {ContactForm} from "../../components/ContactForm/ContactFrom";
import './ContactUs.scss';
import ContactService from "../../../services/ContactService";

export const ContactUs = () => {

    const [contactData, setContactData] = useState({
        email: '',
        phone: '',
        name: '',
    });

    const [venueData, setVenueData] = useState({
        venueName: '',
        address: ''
    });

    const onChangeContactHandler = useCallback((event) => {
        const {value, name} = event.target;
        setContactData(prevState => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    }, []);

    const onChangeVenueHandler = useCallback((event) => {
        const {value, name} = event.target;
        setVenueData(prevState => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    }, [])

    const onSubmitHandler = useCallback(async (e) => {
        e.preventDefault();

        try {
            const data = {
                ...contactData,
                venue: {
                    ...venueData,
                }
            };

            const response = await ContactService.postRequest(data);
            if (response) {
                alert('Success');
            }
        } catch (e) {
            console.log(e);
        }
    }, [contactData, venueData])

    return (
        <div className='contactUs'>
            <h2>Contact Us</h2>
            <ContactForm
                onChangeVenue={onChangeVenueHandler}
                onChangeContact={onChangeContactHandler}
                onSubmit={onSubmitHandler}
            />
        </div>
    )
}