import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import '../src/scss/style.scss';
import Routing from "./view/components/Router/Router";

function App() {

    return (
        <Routing />
    );
}

export default App;
